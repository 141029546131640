import React, { useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import style from "./contact.module.css";

const Contact = () => {
  return (
    <>
   
            <div className={`${style.contact_body}`}>
          <div className="container py-lg-5 py-1">
            <div className="row text-center pb-lg-5 pb-4">
              <h3
                className={`${style.contact_txt} display-3 title-contact`}
              >
                Contact
              </h3>
              <span className={`${style.contact_child_txt} h5 py-2`}>
                <span
                className={`${style.text_orange} h5 py-2`}
                //   data-aos="fade-up"
                  style={{ fontWeight: "500" }}
                >
                  Need Help?&nbsp;
                </span>
                <span>Contact Us</span>
              </span>
            </div>
            <div className="row py-lg-3 py-0">
              <div className="col-12">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.216772535028!2d72.89567577471958!3d21.22325098107118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f620e571e39%3A0xd44a109fcf54231f!2sb%2C%20A-77%2C%20Siddharth%20Nagar%20Society%2C%20Nana%20Varachha%2C%20Surat%2C%20Gujarat%20395006!5e0!3m2!1sen!2sin!4v1728216492692!5m2!1sen!2sin"      
              width={"100%"}
              height={"500px"} 
              style={{ border: 0 }} 
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
              </iframe>
                {/* <iframe
                 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1222073449103!2d106.77590781537452!3d-6.2476228629146675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f11b12c11ab7%3A0xcd48f5c775249316!2sHumanity%20First%20Indonesia!5e0!3m2!1sid!2sid!4v1605684563677!5m2!1sid!2sid"
                  width={"100%"}
                  height={"500px"}
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Humanity First Indonesia"
                /> */}
                {/* <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.460732807421!2d72.87841891532808!3d21.161280985927045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fa447cb875d%3A0x1bc7f12f74e9bcd7!2sB-77%2C%20Sidhharth%20Nagar%20Society%2C%20Simada%20Gam%2C%20Surat%2C%20Gujarat%20395006%2C%20India!5e0!3m2!1sen!2sid!4v1696583894454!5m2!1sen!2sid"
  width="100%"
  height="500px"
  frameborder="0"
  style={{ border: 0 }}
  allowfullscreen=""
  aria-hidden="false"
  tabindex="0"
  title="B-77, Sidhharth Nagar Society, Simada Gam, Surat"
></iframe> */}
              </div>
            </div>
            <div className="row py-2 py-lg-5">
              <div
                className="col-lg-6 col-md-6 col-sm-12 py-lg-0 py-2"
                
              >
                <div
                  className={`${style.social_sec} d-flex justify-content-start align-items-center  px-4`}
                >
                  <i className={`${style.text_orange} bi bi-envelope fs-1 pe-4 m-0`}></i>
                  <div>
                    <div>
                      <span className={`${style.social_title} m-0 p-0`}>
                        Email Us
                      </span>
                    </div>
                    <span className={`${style.contact_regular_txt} m-0 p-0`}>
                      rrenterprise664@gmail.com
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 py-lg-0 py-2"
                
              >
                <div
                  className={`${style.social_sec} d-flex justify-content-start align-items-center  px-4`}
                >
                  <i className={`${style.text_orange} bi bi-telephone fs-1 pe-4 m-0`}></i>
                  <div>
                    <div>
                      <span className={`${style.social_title} m-0 p-0`}>
                        Call Us
                      </span>
                    </div>
                    <span className={`${style.contact_regular_txt} m-0 p-0`}>
                      +91 97275 47477
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 py-lg-4 py-2"
                
              >
                {/* <div
                  className={`${style.social_sec} d-flex justify-content-start align-items-center  px-4`}
                >
                  <i className={`${style.text_orange} bi bi-clock fs-1 pe-4 m-0`}></i>
                  <div>
                    <div>
                      <span className={`${style.social_title} m-0 p-0`}>
                        Opening Hours
                      </span>
                    </div>
                    <span className={`${style.contact_regular_txt} m-0 p-0`}>
                      <strong>Mon-Sat:</strong> 11AM - 23PM{" "}
                      <strong>Sunday:</strong> Closed
                    </span>
                  </div>
                </div> */}
              </div>
              {/* <div className='col-lg-6 col-md-6 col-sm-12 py-lg-4 py-2'>
              <div className='d-flex justify-content-start align-items-center social_sec px-4'>
                <i className="bi bi-envelope text-success fs-1 pe-4 m-0"></i>
                <div>
                  <div>
                    <span className='m-0 p-0 social_title'>
                      Email Us
                    </span>
                  </div>
                  <span className='m-0 p-0 contact_regular_txt'>
                    zestfood@gmail.com
                  </span>
                </div>
              </div>
            </div> */}
            </div>

            <div
              className={`${style.details_sec} row d-flex justify-content-center align-items-center py-4`}
            >
              <div className="col-lg-6 col-12 form-group py-4">
                <div className="card">
                    
                  <input
                    name="name"
                    type="text"
                    className={`${style.form_control} ${style.contact_regular_txt} p-2`}
                    placeholder="Enter your name"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 form-group pb-lg-0 pb-md-0 pb-4">
                <div className="card">
                  <input
                    name="mobile"
                    type="number"
                    className={`${style.form_control} ${style.contact_regular_txt} p-2`}
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              <div className="col-12 form-group pb-4">
                <div className="card">
                  <input
                    name="email"
                    type="email"
                    className={`${style.form_control} ${style.contact_regular_txt} p-2`}
                    placeholder="Enter email"
                  />
                </div>
              </div>
              <div className="col-12 form-group pb-4">
                <div className="card">
                  <textarea
                    cols={16}
                    rows={10}
                    name="message"
                    type="text"
                    className={`${style.form_control} ${style.contact_regular_txt} p-2`}
                    placeholder="Enter message"
                  />
                </div>
              </div>
              <div className="col-12 form-group pb-5 d-flex justify-content-center">
              <button className={`${style.custom_btn} ${style.contact_regular_txt}`}>Submit</button>
                {/* <div className="card">
                  <button
                    className={`${style.custom_btn} ${style.contact_regular_txt}`}
                  >
                    Submit
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Contact;
