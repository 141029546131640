import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import style from "./header.module.css";
import Logo from "../../assets/Images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WholeLogo from "../../../src/assets/Images/Whole/LOGO.jpeg.jpg"

const Header = () => {
  const navbarCollapseRef = useRef(null);
  const Currentlocation = useLocation();
  const queryParams = new URLSearchParams(Currentlocation.search);
  const category = queryParams.get("category");
  const handleNavCollapse = () => {
    if (navbarCollapseRef.current.classList.contains("show")) {
      navbarCollapseRef.current.classList.remove("show");
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            {category == 'wholespices' ? (
              <>
                <img
                  src={WholeLogo}
                  className="navbar-brand-image img-fluid"
                  alt="WholeLogo"
                />
              </>
            ) : (
              <>
                <img
                  src={Logo}
                  className="navbar-brand-image img-fluid"
                  alt="Logo"
                />
              </>
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
            ref={navbarCollapseRef}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link click-scroll"
                  to="/"
                  onClick={handleNavCollapse}
                >
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link click-scroll"
                  to="/about"
                  onClick={handleNavCollapse}
                >
                  About
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link click-scroll"
                  to="/products"
                  onClick={handleNavCollapse}
                >
                  Products
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={`nav-link click-scroll ${style.contact_us_link}`}
                  to="/contact"
                  onClick={handleNavCollapse}
                >
                  
                  Contact us

                </Link>
              </li>
            </ul>
          </div>

          {
            category == 'wholespices' && (<> <Link className="navbar-brand " to="/">
              {category == 'wholespices' ? (
                <>
                  <img
                    src={Logo}
                    className="navbar-brand-image img-fluid"
                    alt="WholeLogo"
                  />
                </>
              ) : (
                <>
               
                </>
              )}
            </Link></>)
          }
        </div>
      </nav>
    </>
  );
};

export default Header;
