import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./ComonComponents/Header/header";
import Footer from "./ComonComponents/Footer/footer";
import FloatingButton from "./components/FloatingButton/FloatingButton";

// Import pages
import Home from "./pages/Home";
import Product from "./pages/Products";
import Contact from "./pages/Contact";
import About from "./pages/About";
import ProductDetails from "./pages/ProductDetails";

// // Lazy load the components (Optional if you want lazy loading)
// const Home = lazy(() => import("./pages/Home"));
// const Product = lazy(() => import("./pages/Products"));
// const Contact = lazy(()=>import("./pages/Contact"))
// const About = lazy(()=>import("./pages/About"))
// const ProductDetails = lazy(()=>import("./pages/ProductDetails/index"))

const Loader = () => (
  <div className="loading-container">
    <div className="loader"></div>
  </div>
);

function App() {
  return (
    <>
      {/* Common Components */}
      <Header />
      
      {/* Routes */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/product-detail" element={<ProductDetails />} />
      </Routes>

      {/* Common Components */}
      <Footer />
      
      <FloatingButton />
    </>
  );
}

export default App;
