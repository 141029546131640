import React from "react";
import style from "./ProductDetail.module.css";
import { Link } from "react-router-dom";
import all_product from "../../assets/Images/Products/all_product.jpg";
import social_media from "../../assets/Images/Products/social_media.avif";
import blended_banner from "../../assets/Images/Products/blended_banner.avif";
import pureSpice_banner from "../../assets/Images/Products/pureSpice_banner.avif";
import Wholespice_banner from "../../assets/Images/Products/Wholespice_banner.jpg";
import grid_first from "../../assets/Images/Products/grid_1.webp";
import grid_second from "../../assets/Images/Products/grid_2.webp";
import grid_three from "../../assets/Images/Products/grid_3.webp";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useLocation, useNavigate } from "react-router-dom";
import Fancybox from "../../ComonComponents/FancyBox/fancybox";
import BlendedSpicesPro from "../../assets/Images/Products/img/BlendedSpices.jpeg";
import PureSpices from "../../assets/Images/Products/img/PURE SPICES.jpeg";
import WholeSpices from "../../assets/Images/Products/img/WHOLE SPICES.jpeg";
import Primix from "../../assets/Images/Products/img/PRIMIX SPICES.jpg";
import Herbsmix from "../../assets/Images/Products/img/HERBSMIX SPICES.jpg";
import Seasonings from "../../assets/Images/Products/img/SEASONINGS SPICES.jpg";
import CinnamonStickes from "../../assets/Images/Products/img/CINNAMON STICKES.png";
import Primix1 from "../../assets/Images/Sambhar Premix/11.jpg";
import Primix2 from "../../assets/Images/Sambhar Premix/22.jpg";
import Primix3 from "../../assets/Images/Sambhar Premix/333.jpg";
import Primix4 from "../../assets/Images/Sambhar Premix/444.jpg";
import Primix5 from "../../assets/Images/Sambhar Premix/555.jpg";
import GarlicBread1 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/11.jpg";
import GarlicBread2 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/22.jpg";
import GarlicBread3 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/33.jpg";
import GarlicBread4 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/44.jpg";
import GarlicBread5 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/55.jpg";
import GarlicBread6 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/66.jpg";
import Pizza1 from "../../assets/Images/Pizza Seasoning Post/111.jpg";
import Pizza2 from "../../assets/Images/Pizza Seasoning Post/222.jpg";
import Pizza3 from "../../assets/Images/Pizza Seasoning Post/333.jpg";
import Pizza4 from "../../assets/Images/Pizza Seasoning Post/444.jpg";
import Pizza5 from "../../assets/Images/Pizza Seasoning Post/555.jpg";
import Pizza6 from "../../assets/Images/Pizza Seasoning Post/666.jpg";
import Cheese1 from "../../assets/Images/Cheese/E-Commerce Post_page-0001.jpg";
import Cheese2 from "../../assets/Images/Cheese/E-Commerce Post_page-0002.jpg";
import Cheese3 from "../../assets/Images/Cheese/E-Commerce Post_page-0003.jpg";
import Cheese4 from "../../assets/Images/Cheese/E-Commerce Post_page-0004.jpg";
import Cheese5 from "../../assets/Images/Cheese/E-Commerce Post_page-0005.jpg";
import Cheese6 from "../../assets/Images/Cheese/E-Commerce Post_page-0006.jpg";
import Tomatocheese1 from "../../assets/Images/Tomato cheese/111.jpg";
import Tomatocheese2 from "../../assets/Images/Tomato cheese/222.jpg";
import Tomatocheese3 from "../../assets/Images/Tomato cheese/333.jpg";
import Tomatocheese4 from "../../assets/Images/Tomato cheese/444.jpg";
import Tomatocheese5 from "../../assets/Images/Tomato cheese/555.jpg";
import Tomatocheese6 from "../../assets/Images/Tomato cheese/666.jpg";
import TengyTometo1 from "../../assets/Images/Tengy Tometo/111.jpg";
import TengyTometo2 from "../../assets/Images/Tengy Tometo/222.jpg";
import TengyTometo3 from "../../assets/Images/Tengy Tometo/333.jpg";
import TengyTometo4 from "../../assets/Images/Tengy Tometo/444.jpg";
import TengyTometo5 from "../../assets/Images/Tengy Tometo/555.jpg";
import TengyTometo6 from "../../assets/Images/Tengy Tometo/666.jpg";
import PeriPeri1 from "../../assets/Images/peri peri/111.jpg";
import PeriPeri2 from "../../assets/Images/peri peri/222.jpg";
import PeriPeri3 from "../../assets/Images/peri peri/333.jpg";
import PeriPeri4 from "../../assets/Images/peri peri/444.jpg";
import PeriPeri5 from "../../assets/Images/peri peri/555.jpg";
import PeriPeri6 from "../../assets/Images/peri peri/666.jpg";
import Pudina1 from "../../assets/Images/Pudina/111.png";
import Pudina2 from "../../assets/Images/Pudina/222.png";
import Pudina3 from "../../assets/Images/Pudina/333.png";
import LemonTest1 from "../../assets/Images/Lemon Test/111.png";
import LemonTest2 from "../../assets/Images/Lemon Test/222.png";
import LemonTest3 from "../../assets/Images/Lemon Test/333.png";
import Jainperi1 from "../../assets/Images/Jain Peri peri/111.png";
import Jainperi2 from "../../assets/Images/Jain Peri peri/222.png";
import Jainperi3 from "../../assets/Images/Jain Peri peri/333.png";
import DefaultPng from "../../assets/Images/Products/img/default-image.png"
import KashmiriChilliPowder from "../../assets/Images/Products/img/Kashmiri Chilli Powder.jpg";
import TurmericPowder from "../../assets/Images/Products/img/Turmeric Powder.jpg";
import CuminCorianderPowder from "../../assets/Images/Products/img/Cumin Coriander Powder.jpg";
import RedChilliPowder from "../../assets/Images/Products/img/Red Chilli Powder.jpg";





const ProductDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productDetails = queryParams.get("detail");

  const DetailArray = [
    {
      detailsOff: "Cheddar Cheese Seasoning",
      image: `${blended_banner}`,
      productName: "Cheddar Cheese",
      description:
        "ZEST Cheddar Cheese seasoning is crafted with real dehydrated cheese powder, delivering an authentic and rich cheesy flavor to your favorite snacks. Perfect for popcorn, makhana, chips,and fryums, this seasoning offers a smooth, creamy taste with every sprinkle. Made with high-quality ingredients, it ensures an indulgent and savory experience, elevating your snacks with the unmistakable goodness of real cheddar cheese.",
      innerProduct: [
        {
          image: Cheese1,
          alt: "Cheese",
        },
        {
          image: Cheese2,
          alt: "Cheese",
        },
        {
          image: Cheese3,
          alt: "Cheese",
        },
        {
          image: Cheese4,
          alt: "Cheese",
        },
        {
          image: Cheese5,
          alt: "Cheese",
        },
        {
          image: Cheese6,
          alt: "Cheese",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "pale yellow",
        FlavourProfile: "Creamy & Buttery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Sambhar Primix",
      image: `${blended_banner}`,
      productName: "Premix-Gallary",
      description:
        "ZEST Sambhar Premix is a hassle-free solution that delivers the true essence of South Indian cuisine right to your table. Formulated with a distinctive blend of spices, this instant mix allows you to whip up delicious sambhar effortlessly—just open the pack, combine with water, boil, and serve. Ideal for busy households, ZEST Sambhar Premix brings the authentic flavors and aromas of traditional sambhar to your meal in a matter of minutes. Enjoy a comforting, homemade dish with every serving!ZEST Cheddar Cheese seasoning is crafted with real dehydrated cheese powder, delivering an authentic and rich cheesy flavor to your favorite snacks. Perfect for popcorn, makhana, chips,and fryums, this seasoning offers a smooth, creamy taste with every sprinkle. Made with high-quality ingredients, it ensures an indulgent and savory experience, elevating your snacks with the unmistakable goodness of real cheddar cheese.",
      innerProduct: [
        {
          image: Primix1,
          alt: "Primix1",
        },
        {
          image: Primix2,
          alt: "Primix",
        },
        {
          image: Primix3,
          alt: "Primix",
        },
        {
          image: Primix4,
          alt: "Primix",
        },
        {
          image: Primix5,
          alt: "Primix",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "pale yellow",
        FlavourProfile: "Creamy & Buttery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Garlic Bread Seasoning",
      image: `${blended_banner}`,
      productName: "Garlic-Bread",
      description:"",
      innerProduct: [
        {
          image: GarlicBread1,
          alt: "GarlicBread1",
        },
        {
          image: GarlicBread2,
          alt: "GarlicBread",
        },
        {
          image: GarlicBread3,
          alt: "GarlicBread",
        },
        {
          image: GarlicBread4,
          alt: "GarlicBread",
        },
        {
          image: GarlicBread5,
          alt: "GarlicBread",
        },
        {
          image: GarlicBread6,
          alt: "GarlicBread",
        },
      ],
      productdetails: {
        Application: "Pizza-Pasta Dishes, Seasonings",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "light yellowish-green",
        FlavourProfile: "Garlicky & Pungent",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Dried Oregano",
      image: `${blended_banner}`,
      productName: "dried-Oregano",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Application: "Topping",
        Variant: "60gm, 200gm, 500gm, 1kg",
        Colour: "Dull green",
        FlavourProfile: "Herbaceous & Earthy",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Dried Chilli Flakes",
      image: `${blended_banner}`,
      productName: "dried-chilli-flackes",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Application: "Topping",
        Variant: "60gm, 200gm, 500gm, 1kg",
        Colour: " bright red and deep red",
        FlavourProfile: "Spicy & Hot",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Dried Basil",
      image: `${blended_banner}`,
      productName: "dried-basil",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Application: "Topping",
        Variant: "60gm, 200gm, 500gm, 1kg",
        Colour: "olive green",
        FlavourProfile: "Aromatic",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Cinnamon Stickes",
      image: `${blended_banner}`,
      productName: "Cinnamon",
      noRecord: true,
    },
    {
      detailsOff: "Pizza Pasta Seasoning",
      image: `${blended_banner}`,
      productName: "Pizza",
      description:
        "ZEST Pizza and Pasta seasoning is a delightful blend of oregano, basil, and a selection of authentic Italian herbs, designed to bring the essence of Italy to your kitchen. This aromatic seasoning enhances the flavor of pizzas, pastas, and sauces, delivering a rich and savory taste with every sprinkle. Elevate your culinary creations with ZEST Pizza and Pasta seasoning and enjoy the true flavors of Italian cuisine in your favorite dishes!",
      innerProduct: [
        {
          image: Pizza1,
          alt: "Pizza1",
        },
        {
          image: Pizza2,
          alt: "Pizza",
        },
        {
          image: Pizza3,
          alt: "Pizza",
        },
        {
          image: Pizza4,
          alt: "Pizza",
        },
        {
          image: Pizza5,
          alt: "Pizza",
        },
        {
          image: Pizza6,
          alt: "Pizza",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "pale yellow",
        FlavourProfile: "Creamy & Buttery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Oregano",
      image: `${blended_banner}`,
      productName: "Oregano",
      description:
        " ZEST Oregano is a premium pack of dehydrated Italian herbs, offering the robust flavor and aromatic qualities of fresh oregano. Perfect for enhancing pizzas, pastas, salads, and sauces, this versatile herb adds a delightful touch to your culinary creations. Conveniently packaged for freshness, ZEST Oregano brings the authentic taste of Italy to your kitchen, making it an essential ingredient for any home chef. Elevate your dishes with the rich, aromatic essence of ZEST Oregano!",
      innerProduct: [
        {
          image: Pizza1,
          alt: "Pizza1",
        },
        {
          image: Pizza2,
          alt: "Pizza",
        },
        {
          image: Pizza3,
          alt: "Pizza",
        },
        {
          image: Pizza4,
          alt: "Pizza",
        },
        {
          image: Pizza5,
          alt: "Pizza",
        },
        {
          image: Pizza6,
          alt: "Pizza",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "pale yellow",
        FlavourProfile: "Creamy & Buttery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Tomato Cheese Seasoning",
      image: `${blended_banner}`,
      productName: "Tomato-Cheese",
      description:
        "ZEST Tomato Cheese seasoning is a flavorful blend made with real dehydrated cheese, spray-dried tomato powder, and a unique combination of spices. This seasoning brings together the creamy richness of cheese and the tangy zest of tomatoes, perfect for enhancing the flavor of popcorn, makhana, chips, and fryums. With its balanced blend of savory and tangy notes, ZEST Tomato Cheese seasoning offers a delicious and irresistible twist to your favorite snacks.",
      innerProduct: [
        {
          image: Tomatocheese1,
          alt: "Tomatocheese1",
        },
        {
          image: Tomatocheese2,
          alt: "Tomatocheese",
        },
        {
          image: Tomatocheese3,
          alt: "Tomatocheese",
        },
        {
          image: Tomatocheese4,
          alt: "Tomatocheese",
        },
        {
          image: Tomatocheese5,
          alt: "Tomatocheese",
        },
        {
          image: Tomatocheese6,
          alt: "Tomatocheese",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "light reddish-orange",
        FlavourProfile: "Tangy & Sweet",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Tangy Tomatino Seasoning",
      image: `${blended_banner}`,
      productName: "Tangy-Tomatino",
      description:
        "ZEST Tangy Tomatino seasoning is a zesty fusion crafted with premium spray-dried tomato powder and a curated blend of aromatic spices. This tangy delight infuses your snacks with a bold, vibrant flavor profile, adding a burst of zest to popcorn, makhana, chips, and fryums. With its rich tomato essence and an exquisite medley of spices, ZEST Tangy Tomatino turns ordinary snacking into an extraordinary, flavorful indulgence.",
      innerProduct: [
        {
          image: TengyTometo1,
          alt: "TengyTometo1",
        },
        {
          image: TengyTometo2,
          alt: "TengyTometo",
        },
        {
          image: TengyTometo3,
          alt: "TengyTometo",
        },
        {
          image: TengyTometo4,
          alt: "TengyTometo",
        },
        {
          image: TengyTometo5,
          alt: "TengyTometo",
        },
        {
          image: TengyTometo6,
          alt: "TengyTometo",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "Orange-Red",
        FlavourProfile: "Bright & Zesty",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Peri Peri Masala Seasoning",
      image: `${blended_banner}`,
      productName: "Peri-Peri",
      description:
        "ZEST Peri Peri Masala seasoning is a bold, fiery blend of handpicked spices, crafted to elevate your French fries with an irresistible kick of flavor. Infused with a tantalizing mix of heat and zest, this seasoning transforms ordinary fries into a sensational taste experience. Perfect for adding a spicy, vibrant twist, ZEST Peri Peri Masala brings the ultimate snacking adventure to your French fries, ensuring every bite is packed with bold flavor and excitement.",
      innerProduct: [
        {
          image: PeriPeri1,
          alt: "PeriPeri1",
        },
        {
          image: PeriPeri2,
          alt: "PeriPeri",
        },
        {
          image: PeriPeri3,
          alt: "PeriPeri",
        },
        {
          image: PeriPeri4,
          alt: "PeriPeri",
        },
        {
          image: PeriPeri5,
          alt: "PeriPeri",
        },
        {
          image: PeriPeri6,
          alt: "PeriPeri",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "Reddish-orange",
        FlavourProfile: "Spicy & Fiery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Pudina Seasoning",
      image: `${blended_banner}`,
      productName: "Pudina",
      description:"",
      innerProduct: [
        {
          image: Pudina1,
          alt: "Pudina1",
        },
        {
          image: Pudina2,
          alt: "Pudina",
        },
        {
          image: Pudina3,
          alt: "Pudina",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "Light Apple Green",
        FlavourProfile: "Herbaceous",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Lemon Test Seasoning",
      image: `${blended_banner}`,
      productName: "Lemon-Test",
      description:
        "ZEST Lemon Test Seasoning could be imagined as a vibrant, tangy spice blend inspired by the freshness and zesty punch of lemons, with a versatile mix of herbs and spices that can elevate various dishes. This seasoning is centered around lemon zest or dried lemon powder, which provides a bright, citrusy flavor. Complementary spices such as black pepper, paprika, and cumin might be added to balance the tartness with subtle heat and earthiness.",
      innerProduct: [
        {
          image: Pudina1,
          alt: "Pudina1",
        },
        {
          image: Pudina2,
          alt: "Pudina",
        },
        {
          image: Pudina3,
          alt: "Pudina",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "Mocha",
        FlavourProfile: "Bright & Citrus",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Jain Peri Peri Masala",
      image: `${blended_banner}`,
      productName: "Jain-Peri-Peri",
      description:
        "ZEST Jain-Peri-Peri Seasoning is a specially crafted spice blend that brings the fiery and tangy flavors of traditional Peri-Peri seasoning to Jain cuisine, adhering strictly to Jain dietary restrictions. The seasoning is free from garlic, onions, and root vegetables, which are avoided in Jainism, while still delivering the bold, spicy kick that defines Peri-Peri.",
      innerProduct: [
        {
          image: Jainperi1,
          alt: "Jainperi",
        },
        {
          image: Jainperi2,
          alt: "Jainperi",
        },
        {
          image: Jainperi3,
          alt: "Jainperi",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "reddish-orange",
        FlavourProfile: "Spicy & Fiery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Star Anise",
      image: `${blended_banner}`,
      productName: "Star-Anise",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "13.5 % max",
        Admixture: "1% max,No fungus, natural colour",
        Flowersize:"2.5 cm up (80% min)",
        Broken:"5% max",
        Packing: "carton (net 10kg or 20kg)",
        CountryofOrigin: "Viet Nam",
        ContainerCapacity:"6,5 MT/20FCL, 15 MT/40HC",
      },
    },
    {
      detailsOff: "Finger Cassia",
      image: `${blended_banner}`,
      productName: "Finger-Cassia",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "13% max",
        Admixture: "0.5% max",
        Length:"8-20cm",
        Flowersize:"2.5 cm up (80% min)",
        Colour:"Natural colour, smooth touch",
        Packing: "cartons (net 10kg or 20kg) or customized",
        CountryofOrigin: "Viet Nam",
        ContainerCapacity:"6,5 MT/20FCL, 15 MT/40HC",
      },
    },
    {
      detailsOff: "Split Cassia",
      image: `${blended_banner}`,
      productName: "split-cassia",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "13.5% max",
        Admixture: "1% max",
        Length:"25 – 45cm (80% min)",
        Colour:"No fungus, yellow/reddish colour",
        Packing: "cartons (net10kg)",
        CountryofOrigin: "Viet Nam",
        ContainerCapacity:"6,5MT/ 20FCL ; 14MT/ 40HC",
      },
    },
    {
      detailsOff: "Clove (Lalpari)",
      image: `${blended_banner}`,
      productName: "clove",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "13.5% max",
        Admixture: "1% max",
        Headless:"3% max",
        Length:"25 – 45cm (80% min)",
        Colour:"No fungus, 3% max",
        Packing: "cartons (net10kg)",
        CountryofOrigin: "Madagaskar",
        ContainerCapacity:"10.5 – 15MT/20FCL; 25 MT/40HC",
      },
    },
    {
      detailsOff: "Cardamom",
      image: `${blended_banner}`,
      productName: "cardamom",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "10% max",
        Length:"6,7,8mm",
        Colour:"Green",
        Packing: "carton (net 10kg or 20kg)",
        CountryofOrigin: "India",
        ContainerCapacity:"5,7MT/ 20FCL ; 13MT/ 40HC",
      },
    },
    {
      detailsOff: "Black Pepper",
      image: `${blended_banner}`,
      productName: "black-pepper",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "13 max",
        Admixture: "1% max",
        Density   :"500 – 580 g/l",
        Packing: "PP bag (net :25/50kg)",
        CountryofOrigin: "Sri Lanka",
        ContainerCapacity:"13,5 – 17MT/20FCL; 28 MT/40HC",
      },
    },
     {
      detailsOff: "Ceylon Cinnamon Stick",
      image: `${blended_banner}`,
      productName: "ceylon-cinnamon-stick",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Moisture: "13% max",
        Admixture: "0.5% max",
        Length:"8-15cm",
        Colour:"Natural colour, smooth touch",
        Packing: "cartons (net 10kg or 20kg) or customized",
        CountryofOrigin: "india/Sri lanka",
        ContainerCapacity:"7,5-8MT/20FCL; 17.5MT/40HC",
      },
    },
    {
      detailsOff: "Jain Peri Peri Seasoning",
      image: `${blended_banner}`,
      productName: "Jain-Peri-Peri-Seasoning",
      description:
        "ZEST Jain-Peri-Peri Seasoning is a specially crafted spice blend that brings the fiery and tangy flavors of traditional Peri-Peri seasoning to Jain cuisine, adhering strictly to Jain dietary restrictions. The seasoning is free from garlic, onions, and root vegetables, which are avoided in Jainism, while still delivering the bold, spicy kick that defines Peri-Peri.",
      innerProduct: [
        {
          image: Pudina1,
          alt: "Pudina1",
        },
        {
          image: Pudina2,
          alt: "Pudina",
        },
        {
          image: Pudina3,
          alt: "Pudina",
        },
      ],
      productdetails: {
        Application: "Prime Seasoning/Flavour",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "reddish-orange",
        FlavourProfile: "Spicy & Fiery",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Garam Masala",
      image: `${blended_banner}`,
      productName: "garam-masala",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Application: "Curries, Stews and Marinades",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "deep brown",
        FlavourProfile: "Warm & Spicy",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Kutchi Dabeli Masala",
      image: `${blended_banner}`,
      productName: "kutchi-dabeli",
      description:
        "",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "reddish-brown",
        FlavourProfile: "Sweet & Savory",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Pani Puri",
      image: `${blended_banner}`,
      productName: "panipuri",
      description:
        "",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Application: "Panipuri",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "vibrant green",
        FlavourProfile: "Spicy & Tangy",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Sambhar Masala",
      image: `${blended_banner}`,
      productName: "sambhar",
      description:"",
      innerProduct: [
        {
          image: Primix1,
          alt: "Primix1",
        },
        {
          image: Primix2,
          alt: "Primix",
        },
        {
          image: Primix3,
          alt: "Primix",
        },
        {
          image: Primix4,
          alt: "Primix",
        },
        {
          image: Primix5,
          alt: "Primix",
        },
      ],
      productdetails: {
        Application: "Sambhar Curry",
        Variant: "100gm, 250gm, 500gm, 1kg",
        Colour: "reddish-brown",
        FlavourProfile: "Sweet & Savory",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Turmeric Powder",
      image: `${blended_banner}`,
      productName: "turmeric-powder",
      description:
        "",
      innerProduct: [
        {
          image: TurmericPowder,
          alt: "TurmericPowder",
        },
      ],
      productdetails: {
        Application: "Indian Cooking",
        Variant: "250gm, 500gm, 1kg",
        Colour: "bright yellow",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Chilli Powder",
      image: `${blended_banner}`,
      productName: "chilli-Powder",
      description:"",
      innerProduct: [
        {
          image: RedChilliPowder,
          alt: "RedChilliPowder",
        },
      ],
      productdetails: {
        Application: "Indian Cooking",
        Variant: "250gm, 500gm, 1kg",
        Colour: "deep red",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Coriander Cumin Powder",
      image: `${blended_banner}`,
      productName: "coriander-cumin-powder",
      description:"",
      innerProduct: [
        {
          image: Pudina1,
          alt: "Pudina1",
        },
        {
          image: Pudina2,
          alt: "Pudina",
        },
        {
          image: Pudina3,
          alt: "Pudina",
        },
      ],
      productdetails: {
        Application: "Indian Cooking",
        Variant: "250gm, 500gm, 1kg",
        Colour: "Dull Brown",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Coriander Powder",
      image: `${blended_banner}`,
      productName: "coriander-powder",
      description:"",
      innerProduct: [
        {
          image: CuminCorianderPowder,
          alt: "CuminCorianderPowder",
        },
      ],
      productdetails: {
        Application: "Indian Cooking",
        Variant: "250gm, 500gm, 1kg",
        Colour: "Dull Brown",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "kashmiri Red Chilli Powder",
      image: `${blended_banner}`,
      productName: "kashmiri-red-chilli-powder",
      description:"",
      innerProduct: [
        {
          image: KashmiriChilliPowder,
          alt: "KashmiriChilliPowder",
        },
      ],
      productdetails: {
        Application: "Indian Cooking",
        Variant: "250gm, 500gm, 1kg",
        Colour: "vibrant red",
        BulkPacking: "25Kg",
        PackagingMaterial: "PP Bag",
        CountryofOrigin: "India",
      },
    },




    {
      detailsOff: "Pink Onion Flackes",
      image: `${blended_banner}`,
      productName: "pink-onion-flackes",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Pink",
        Size: "8 to 15 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "14kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Pink Onion Chopped",
      image: `${blended_banner}`,
      productName: "pink-onion-chopped",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Pink",
        Size: "3 to 5 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Pink Onion Minced",
      image: `${blended_banner}`,
      productName: "pink-onion-minced",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Pink",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Pink Onion Granules",
      image: `${blended_banner}`,
      productName: "pink-onion-granules",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Pink",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Pink Onion Powder",
      image: `${blended_banner}`,
      productName: "pink-onion-powder",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Pink",
        Size: "80 to 100 Mesh",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },




    {
      detailsOff: "Red Onion Flackes",
      image: `${blended_banner}`,
      productName: "red-onion-flackes",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Red",
        Size: "8 to 15 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "14kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Red Onion Chopped",
      image: `${blended_banner}`,
      productName: "red-onion-chopped",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Red",
        Size: "3 to 5 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Red Onion Minced",
      image: `${blended_banner}`,
      productName: "red-onion-minced",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Red",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Red Onion Granules",
      image: `${blended_banner}`,
      productName: "red-onion-granules",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Red",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Red Onion Powder",
      image: `${blended_banner}`,
      productName: "red-onion-powder",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "Red",
        Size: "80 to 100 Mesh",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },




    {
      detailsOff: "White Onion Flackes",
      image: `${blended_banner}`,
      productName: "white-onion-flackes",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "8 to 15 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "14kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "White Onion Chopped",
      image: `${blended_banner}`,
      productName: "white-onion-chopped",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "3 to 5 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "White Onion Minced",
      image: `${blended_banner}`,
      productName: "white-onion-minced",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "White Onion Granules",
      image: `${blended_banner}`,
      productName: "white-onion-granules",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "White Onion Powder",
      image: `${blended_banner}`,
      productName: "white-onion-powder",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "80 to 100 Mesh",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },


    


    {
      detailsOff: "Garlic Flackes",
      image: `${blended_banner}`,
      productName: "garlic-flackes",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "8 to 15 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "14kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },
    {
      detailsOff: "Garlic Chopped",
      image: `${blended_banner}`,
      productName: "garlic-chopped",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "3 to 5 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Garlic Minced",
      image: `${blended_banner}`,
      productName: "garlic-minced",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Garlic Granules",
      image: `${blended_banner}`,
      productName: "garlic-granules",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "1 to 3 mm",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },

    {
      detailsOff: "Garlic Powder",
      image: `${blended_banner}`,
      productName: "garlic-powder",
      description:"",
      innerProduct: [
        {
          image: DefaultPng,
          alt: "DefaultPng",
        },
      ],
      productdetails: {
        Color: "white",
        Size: "80 to 100 Mesh",
        Quality: "Commercial A Grade",
        Aroma: "Strong Aroma of Indian",
        OriginPacking: "20kg Strong Poly Bag in Cartoons",
        CountryofOrigin: "India",
      },
    },
  ];

  return (
    <>
      {DetailArray.map((Item) => {
        console.log(Item, "<<Item");
        return (
          <>
            {Item.noRecord == true ? (
              <>
                {Item.productName == productDetails && (
                  <>
                    <section>
                      <div
                        className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
                        style={{ backgroundImage: `url(${Wholespice_banner})` }}
                      >
                        <div className={`${style.banner_content} w-100`}>
                          <div className="py-3 d-flex justify-content-center text-center flex-column">
                            <h2>{Item.detailsOff}</h2>
                          </div>
                        </div>
                        <div className={`${style.hero_slides}`}></div>
                      </div>
                    </section>
                    <section
                      className={`${style.product_section} container p-3`}
                    >
                      <div className={`${style.all_products} py-5`}>
                        <h1>No Record</h1>
                        <p>
                          It seems like you're asking about a product
                          availability issue. Could you clarify your question or
                          provide more context? Are you looking for information
                          about why a product is unavailable, trying to
                          troubleshoot an issue with a website, or something
                          else? Let me know how I can assist you!
                        </p>
                      </div>
                    </section>
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                {Item.productName == productDetails && (
                  <>
                    <section>
                      <div
                        className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
                        style={{ backgroundImage: `url(${Item.image})` }}
                      >
                        <div className={`${style.banner_content} w-100`}>
                          <div className="py-3 d-flex justify-content-center text-center flex-column">
                            <h2>{Item.detailsOff}</h2>
                          </div>
                        </div>
                        <div className={`${style.hero_slides}`}></div>
                      </div>
                    </section>
                    <div className="container justify-content-center align-items-center py-5">
                      <h2
                        className={`${style.product_heading} d-flex justify-content-center align-items-center text-center`}
                      >
                        {Item.detailsOff}
                      </h2>
                      <div className="row py-5 d-flex">
                        <div className="col-lg-6 d-flex justify-content-center py-3">
                          <Swiper
                            modules={[Pagination, Autoplay, A11y]}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log("slide change")}
                          >
                            {Item.innerProduct.map((slidesItem) => {
                              return (
                                <>
                                  <SwiperSlide>
                                    <div
                                      className={`${style.product_detail_slides}`}
                                    >
                                      {" "}
                                      <img
                                        alt=""
                                        src={slidesItem.image}
                                        width={400}
                                        height={400}
                                      />
                                    </div>
                                  </SwiperSlide>
                                </>
                              );
                            })}
                          </Swiper>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-center py-3">
                          
                            {
                             Item.productdetails.Application && (<>
                             <span className="py-1"><strong>Application :</strong>&nbsp;&nbsp;{Item.productdetails.Application}
                          </span>
                          </>)
                            }
                            {
                             Item.productdetails.Color && (<>
                             <span className="py-1"><strong>Color :</strong>&nbsp;&nbsp;{Item.productdetails.Color}
                          </span>
                          </>)
                            }
                            {
                             Item.productdetails.Size && (<>
                             <span className="py-1"><strong>Size :</strong>&nbsp;&nbsp;{Item.productdetails.Size}
                          </span>
                          </>)
                            }
                            {
                             Item.productdetails.Quality && (<>
                             <span className="py-1"><strong>Quality :</strong>&nbsp;&nbsp;{Item.productdetails.Quality}
                          </span>
                          </>)
                            }
                            {
                             Item.productdetails.Aroma && (<>
                             <span className="py-1"><strong>Aroma :</strong>&nbsp;&nbsp;{Item.productdetails.Aroma}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.OriginPacking && (<>
                             <span className="py-1"><strong>OriginPacking :</strong>&nbsp;&nbsp;{Item.productdetails.OriginPacking}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Moisture && (<>
                             <span className="py-1"><strong>Moisture :</strong>&nbsp;&nbsp;{Item.productdetails.Moisture}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Admixture && (<>
                             <span className="py-1"><strong>Admixture :</strong>&nbsp;&nbsp;{Item.productdetails.Admixture}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Density && (<>
                             <span className="py-1"><strong>Density :</strong>&nbsp;&nbsp;{Item.productdetails.Density}
                          </span>
                          </>)
                            }


{
                             Item.productdetails.Headless && (<>
                             <span className="py-1"><strong>Headless :</strong>&nbsp;&nbsp;{Item.productdetails.Headless}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Flowersize && (<>
                             <span className="py-1"><strong>Flowersize :</strong>&nbsp;&nbsp;{Item.productdetails.Flowersize}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Length && (<>
                             <span className="py-1"><strong>Length :</strong>&nbsp;&nbsp;{Item.productdetails.Length}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Broken && (<>
                             <span className="py-1"><strong>Broken :</strong>&nbsp;&nbsp;{Item.productdetails.Broken}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.Packing && (<>
                             <span className="py-1"><strong>Packing :</strong>&nbsp;&nbsp;{Item.productdetails.Packing}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.ContainerCapacity && (<>
                             <span className="py-1"><strong>ContainerCapacity :</strong>&nbsp;&nbsp;{Item.productdetails.ContainerCapacity}
                          </span>
                          </>)
                            }




                          {
                             Item.productdetails.Variant && (<>
                             <span className="py-1">
                            <strong>Variant :</strong>&nbsp;&nbsp;
                            {Item.productdetails.Variant}
                          </span>
                          </>)
                            }

                            
                          {
                             Item.productdetails.Colour && (<>
                             <span className="py-1">
                            <strong>Colour :</strong>&nbsp;&nbsp;
                            {Item.productdetails.Colour}
                          </span>
                          </>)
                            }

                          {
                             Item.productdetails.FlavourProfile && (<>
                             <span className="py-1">
                            <strong>Flavour Profile :</strong>&nbsp;&nbsp;
                            {Item.productdetails.FlavourProfile}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.BulkPacking && (<>
                               <span className="py-1">
                            <strong>Bulk Packing :</strong>&nbsp;&nbsp;
                            {Item.productdetails.BulkPacking}
                          </span>
                          </>)
                            }

{
                             Item.productdetails.PackagingMaterial && (<>
                                <span className="py-1">
                            <strong>Packaging Material :</strong>&nbsp;&nbsp;
                            {Item.productdetails.PackagingMaterial}
                          </span>
                          </>)
                            }

                  {
                             Item.productdetails.CountryofOrigin && (<>
                                 <span className="py-1">
                            <strong>Country of Origin :</strong>&nbsp;&nbsp;
                            {Item.productdetails.CountryofOrigin}
                          </span>
                          </>)
                            }
                            
                         
                          
                        
                        
                         
                         
                        </div>
                      </div>
                      <div className="row py-5">
                        <p className={`${style.product_decs}`}>
                          {Item.description}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default ProductDetails;
