import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/homeComponents/banner";
import LattestProduct from "../../components/homeComponents/lattestProduct";
import BestSallers from "../../components/homeComponents/bestSallers";
import Followus from "../../components/homeComponents/followUs";
// import Followus from "../../components/homeComponents/Followus"
import About from "../../components/aboutComponents/about";
import style from "./home.module.css";
import cat1 from "../../assets/Images/Home/Categories/cat1.jpg";
import cat2 from "../../assets/Images/Home/Categories/cat2.jpg";
import cat3 from "../../assets/Images/Home/Categories/cat3.jpg";
import cat4 from "../../assets/Images/Home/Categories/cat4.jpg";
import cat5 from "../../assets/Images/Home/Categories/cat5.jpg";
import cat6 from "../../assets/Images/Home/Categories/cat6.jpg";
import AboutHome from "../../assets/Images/Home/HomeAbout/img_5.png";
import discountcard1 from "../../assets/Images/Chilli Flake Post/Pouch/png.png";
import User from "../../assets/Images/Home/HomeFeedBack/avatar.avif";
import FirstBlog from "../../assets/Images/Home/Blog/FirstBlog.jpg"

const Home = () => {

  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate(`/products?category=${encodeURIComponent(category)}`);
  };
  return (
    <>
      <Banner />
      <section className={`${style.categories_sec} container`}>
        <div className={`${style.categories_sec_inner} py-5`}>
          <span className="py-2">DISCOVER BY CATEGORIES</span>
          <h1 className="py-2">Shop By Categories</h1>
          <div className={`${style.categories_slides} row`}>
            <div
              className={`${style.image_sec}  col-lg-2 col-md-4 col-sm-6 py-2`}
              onClick={() => handleClick("blendedspices")}
            >
              <img src={cat1} alt="cat1" />
              <span className={`${style.cat_name} py-2`}>Blended Spices</span>
              {/* <span className={`${style.shopnow}`}>
                <Link to="products?category=blendedspices">SHOP NOW</Link>
              </span> */}
            </div>
            <div
              className={`${style.image_sec} col-lg-2 col-md-4 col-sm-6 py-2`}
              onClick={() => handleClick("PureSpices")}
            >
              <img src={cat2} alt="cat2" />
              <span className={`${style.cat_name} py-2`}>Pure Spices</span>
            </div>
            <div
              className={`${style.image_sec} col-lg-2 col-md-4 col-sm-6 py-2`}
              onClick={() => handleClick("Seasonings")}
            >
              <img src={cat3} alt="cat3" />
              <span className={`${style.cat_name} py-2`}>Seasonings</span>
            </div>
            <div
              className={`${style.image_sec} col-lg-2 col-md-4 col-sm-6 py-2`}
              onClick={() => handleClick("wholespices")}
            >
              <img src={cat4} alt="cat4" />
              <span className={`${style.cat_name} py-2`}>Whole Spices</span>
            </div>
            <div
              className={`${style.image_sec} col-lg-2 col-md-4 col-sm-6 py-2`}
              onClick={() => handleClick("Premix")}
            >
              <img src={cat5} alt="cat4" />
              <span className={`${style.cat_name} py-2`}>Premix</span>
            </div>
            <div
              className={`${style.image_sec} col-lg-2 col-md-4 col-sm-6 py-2`}
              onClick={() => handleClick("HerbsMix")}
            >
              <img src={cat6} alt="cat6" />
              <span className={`${style.cat_name} py-2`}> Herb Mix</span>
            </div>
          </div>
        </div>
      </section>
      <section className={`${style.home_about} container`}>
        <div className={`${style.home_about_sec_inner} py-5`}>
          <span className="py-2">ABOUT US</span>
          <h1 className="py-2">Why Buy From Us?</h1>
          <p>
            At Zest, we believe that every dish deserves to be extraordinary.
            That’s why we offer only the finest, freshest spices sourced from
            the best farms and producers around the globe. Here’s what sets us
            apart:
          </p>
        </div>
        <div className="row">
          <div
            className={`${style.home_about_sec_first} col-lg-4 col-md-12 col-sm-12`}
          >
            <div className={`${style.home_about_inner_content} d-flex  py-3`}>
              <div className="d-flex  flex-column">
                <p className={`${style.elementor_icon_box_title} py-3`}>
                 Premium Quality Spices
                </p>
                <p className={`${style.elementor_icon_box_desc}`}>
                Our spices are sourced from the finest origins, ensuring unmatched freshness and flavor.
                </p>
              </div>
              <img />
            </div>
            <div className={`${style.home_about_inner_content} d-flex  py-3`}>
              <div className="d-flex  flex-column">
                <p className={`${style.elementor_icon_box_title} py-3`}>
                Expertise and Experience
                </p>
                <p className={`${style.elementor_icon_box_desc}`}>
                With over 7 years in the food industry, we bring extensive knowledge and expertise to every product.
                </p>
              </div>
              <img />
            </div>
            <div className={`${style.home_about_inner_content} d-flex  py-3`}>
              <div className="d-flex  flex-column">
                <p className={`${style.elementor_icon_box_title} py-3`}>
                Global Reach
                </p>
                <p className={`${style.elementor_icon_box_desc}`}>
                We serve customers in six countries, delivering trusted spices worldwide.
                </p>
              </div>
              <img />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className={`${style.AboutHome}`}>
              <img src={AboutHome} alt="AboutHome" />
            </div>
          </div>
          <div
            className={`${style.home_about_sec_second} col-lg-4 col-md-12 col-sm-12`}
          >
            <div className={`${style.home_about_inner_content} d-flex  py-3`}>
              <div className="d-flex flex-column">
                <p className={`${style.elementor_icon_box_title} py-3`}>
                Sustainability and Ethical Sourcing
                </p>
                <p className={`${style.elementor_icon_box_desc}`}>
                We prioritize sustainable farming and ethical trade in all our sourcing practices.
                </p>
              </div>
              <img />
            </div>
            <div className={`${style.home_about_inner_content} d-flex  py-3`}>
              <div className="d-flex flex-column">
                <p className={`${style.elementor_icon_box_title} py-3`}>
                Innovation and Customization
                </p>
                <p className={`${style.elementor_icon_box_desc}`}>
                Our diverse range of innovative spice blends caters to every culinary need.
                </p>
              </div>
              <img />
            </div>
            <div className={`${style.home_about_inner_content} d-flex  py-3`}>
              <div className="d-flex flex-column">
                <p className={`${style.elementor_icon_box_title} py-3`}>
                Customer Satisfaction
                </p>
                <p className={`${style.elementor_icon_box_desc}`}>
                We are dedicated to exceptional service and ensuring complete customer satisfaction.
                </p>
              </div>
              <img />
            </div>
          </div>
        </div>
      </section>

      <section className={`${style.discount_card} container`}>
        <div className={`${style.discount_card_sec_inner} py-5`}>
          <span className="d-flex justify-content-center text-center py-2">
            DISCOVER OUR PRODUCTS
          </span>
          <h1 className="d-flex justify-content-center text-center py-2">
            Trending Products
          </h1>
          <div className="row">
            <div className="col-lg-4 col-md-12 py-3">
              <div className={`${style.discount_inner_card} card`}>
                <div className="card-body py-5 d-flex justify-content-center align-items-center text-center flex-column">
                  <div className={`${style.card_content} py-2`}>
                    {/* <span className="py-2">Flat 25% Discount</span> */}
                    <h1 className="py-2">Fresh & Organic</h1>
                    {/* <button>Shop Now</button> */}
                  </div>
                  <div className={`${style.card_content_img} py-2`}>
                    <img src={discountcard1} alt="discountcard1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 py-3">
              <div className={`${style.discount_second_card} card`}>
                <div className="card-body py-5 d-flex justify-content-center align-items-center text-center flex-column">
                  <div className={`${style.card_content} py-2`}>
                    {/* <span className="py-2">Flat 25% Discount</span> */}
                    <h1 className="py-2">Fresh & Organic</h1>
                    {/* <button>Shop Now</button> */}
                  </div>
                  <div className={`${style.card_content_img} py-2`}>
                    <img src={discountcard1} alt="discountcard1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 py-3">
              <div className={`${style.discount_third_card} card`}>
                <div className="card-body py-5 d-flex justify-content-center align-items-center text-center flex-column">
                  <div className={`${style.card_content} py-2`}>
                    {/* <span className="py-2">Flat 25% Discount</span> */}
                    <h1 className="py-2">Fresh & Organic</h1>
                    {/* <button>Shop Now</button> */}
                  </div>
                  <div className={`${style.card_content_img} py-2`}>
                    <img src={discountcard1} alt="discountcard1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${style.feed_back} container`}>
        <div className={`${style.feed_back_sec_inner} py-5`}>
          <span className="d-flex justify-content-center text-center py-2">
            OUR CLIENT FEEDBACK
          </span>
          <h1 className="d-flex justify-content-center text-center py-2">
            What Our Customers Says?
          </h1>
          <div className="row">
            <div className="col-lg-4 col-md-12 py-3">
              <div className={`${style.feed_back_inner_card} card`}>
                <div className="card-body py-5 d-flex justify-content-start align-items-start text-start flex-column">
                  <span>
                    <i
                      className="fa-solid fa-quote-left px-3 py-3"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </span>
                  <p
                    className={`${style.feed_back_inner_card_details_dec} px-3`}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras lacinia magna sed lectus iaculis fringilla. In eu
                    ligula efficitur
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className={`${style.feed_back_inner_card_user_img}`}
                      src={User}
                      alt="User"
                    />
                    &nbsp;&nbsp;
                    <div
                      className={`${style.feed_back_inner_card_details} d-flex justify-content-start align-items-start text-start flex-column`}
                    >
                      <div
                        className={`${style.feed_back_inner_card_details_name}`}
                      >
                        Peter England
                      </div>
                      <p
                        className={`${style.feed_back_inner_card_details_job} m-0 p-0`}
                      >
                        Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 py-3">
              <div className={`${style.feed_back_inner_card} card`}>
                <div className="card-body py-5 d-flex justify-content-start align-items-start text-start flex-column">
                  <span>
                    <i
                      className="fa-solid fa-quote-left px-3 py-3"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </span>
                  <p
                    className={`${style.feed_back_inner_card_details_dec} px-3`}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras lacinia magna sed lectus iaculis fringilla. In eu
                    ligula efficitur
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className={`${style.feed_back_inner_card_user_img}`}
                      src={User}
                      alt="User"
                    />
                    &nbsp;&nbsp;
                    <div
                      className={`${style.feed_back_inner_card_details} d-flex justify-content-start align-items-start text-start flex-column`}
                    >
                      <div
                        className={`${style.feed_back_inner_card_details_name}`}
                      >
                        Peter England
                      </div>
                      <p
                        className={`${style.feed_back_inner_card_details_job} m-0 p-0`}
                      >
                        Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 py-3">
              <div className={`${style.feed_back_inner_card} card`}>
                <div className="card-body py-5 d-flex justify-content-start align-items-start text-start flex-column">
                  <span>
                    <i
                      className="fa-solid fa-quote-left px-3 py-3"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </span>
                  <p
                    className={`${style.feed_back_inner_card_details_dec} px-3`}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras lacinia magna sed lectus iaculis fringilla. In eu
                    ligula efficitur
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      className={`${style.feed_back_inner_card_user_img}`}
                      src={User}
                      alt="User"
                    />
                    &nbsp;&nbsp;
                    <div
                      className={`${style.feed_back_inner_card_details} d-flex justify-content-start align-items-start text-start flex-column`}
                    >
                      <div
                        className={`${style.feed_back_inner_card_details_name}`}
                      >
                        Peter England
                      </div>
                      <p
                        className={`${style.feed_back_inner_card_details_job} m-0 p-0`}
                      >
                        Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={`${style.blog} container`}>
        <div className={`${style.blog_sec_inner} py-5`}>
          <span className="d-flex justify-content-center text-center py-2">
            OUR BLOGS
          </span>
          <h1 className="d-flex justify-content-center text-center py-2">
            Our Latest News!
          </h1>
          <div className="row">
            <div className="col-lg-4 col-md-12 py-3">
              <div classname="card">
                <img src={FirstBlog} classname={`${style.card_img_top} card-img-top`} alt="..." />
                <div classname="card-body">
                  <h5 classname="card-title">How to Write a Blog Post Your Readers Will Love in 5 Steps</h5>
                  <p classname={`${style.card_text}`}>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 py-3">
              <div classname="card">
                <img src={FirstBlog} classname={`${style.card_img_top} card-img-top`} alt="..." />
                <div classname="card-body">
                  <h5 classname="card-title">How to Write a Blog Post Your Readers Will Love in 5 Steps</h5>
                  <p classname={`${style.card_text}`}>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 py-3">
              <div classname="card">
                <img src={FirstBlog} classname={`${style.card_img_top} card-img-top`} alt="..." />
                <div classname="card-body">
                  <h5 classname="card-title">How to Write a Blog Post Your Readers Will Love in 5 Steps</h5>
                  <p classname={`${style.card_text}`}>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className={`${style.lts_Updts} container-fluid`}>
      <div className={`${style.lts_Updts_first}`}>
        <h2>Get Latest Updates</h2>
      </div>
      <div className={`${style.lts_Updts_second}`}><span>Signup and Get special discounts 15% OFF !</span></div>
      <div className={`${style.lts_Updts_thirt}`}><input placeholder="Email"/></div>
      </section> */}
      {/* <About/> */}
      {/* <BestSallers/>
    <Followus/> */}
      {/* <LattestProduct/> */}
    </>
  );
};

export default Home;
