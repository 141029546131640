import React, { useEffect } from 'react'
import about1Img from "../../../assets/Images/About/whoweare.jpg"
import aboutMissionImg from "../../../assets/Images/About/our_journey.jpg"
// import aboutZestImg from ".../../../assets/Images/About/"
import aboutZestWorkImg from "../../../assets/Images/About/about_1.png"
import aboutSafteySecImg from "../../../assets/Images/About/our-safty.jpg"
import aboutZestImg from "../../../assets/Images/About/whyChoose.jpg"
import style from "./about.module.css"


const About = () => {
  return (
    <>
      <section className={`${style.about_section} container`}>
      <div className='about-body'>
                <div className='container py-lg-5 py-1 px-2'>
                    <div className='row text-center pb-lg-5 pb-4'>
                        <h1 className='contact-txt display-3 title-contact' data-aos="fade-down">
                            About Us
                        </h1>
                        <span className='contact-child-txt h5 py-2'>
                            <span className='text-success' data-aos="fade-up" style={{ fontWeight: '500' }}>
                                Learn more&nbsp;
                            </span>
                            <span data-aos="fade-down">
                                About Us
                            </span>
                        </span>
                    </div>

                    <div className={`${style.about_sec_perent} row pb-lg-5 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4 `}>
                        <div className={`${style.about_sec_child} col-lg-6 col-md-6 col-12 py-4 px-lg-4`} data-aos="fade-down">
                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                <strong className={`${style.about_regular_txt} fs-2`}>
                                    1. Who We Are :
                                </strong>
                                <p className={`${style.about_regular_txt} m-0 text-left`}>
                                ZEST, a distinguished brand in the manufacturing, trading, and export of premium spices and seasonings, is a part of R.R Enterprise, managed by the experienced mother-son duo, Mr. Rohan Vaghani and Mrs. Rasila Vaghani, who bring over 6 years of expertise in the food industry. With a strong commitment to quality and innovation, ZEST has established a significant Supply of products in Four countries, delivering a wide range of superior products that cater to global culinary needs. Backed by R.R Enterprise's leadership, ZEST continues to uphold its reputation for excellence in the spice market.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 py-4 text-center' data-aos="fade-up">
                            <div className='d-flex justify-content-center align-items-center text-center'>
                                <img alt='test' className={`${style.side_img}`} src={about1Img} width={'350px'} />
                            </div>
                        </div>
                    </div>

                    <div className='row pb-lg-5 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4'>
                        <div className='col-lg-6 col-md-6 col-12 py-4 text-center' data-aos="fade-down">
                            <div className='d-flex justify-content-center align-items-center text-center'>
                                <img alt='test' className={`${style.side_img}`} src={aboutMissionImg} width={'350px'} />
                            </div>
                        </div>
                        <div className={`${style.about_sec_child} col-lg-6 col-md-6 col-12 py-4 px-lg-4`} data-aos="fade-up">
                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                <strong className={`${style.about_regular_txt} fs-2`}>
                                    2. Our Journey :
                                </strong>
                                <p className={`${style.about_regular_txt} m-0 text-left py-2`}>
                                Founded in 2017, R.R Enterprise laid the groundwork for a vibrant future in the food industry. In 2023, ZEST emerged as a distinguished brand under its umbrella, driven by the expertise of the passionate mother-son duo, Mr. Rohan Vaghani and Mrs. Rasila Vaghani. With over six years of experience, they envisioned a brand dedicated to quality and innovation in the realm of spices and seasonings.
                                </p>
                                <p className={`${style.about_regular_txt} m-0 text-left py-2`}>
                                ZEST has quickly made its mark in the manufacturing, trading, and export of premium spices, establishing a significant presence in four countries. Our commitment to excellence ensures that we deliver a diverse range of superior products, catering to the culinary needs of customers around the globe.
                                </p>
                                <p className={`${style.about_regular_txt} m-0 text-left py-2`}>
                                Backed by the strong leadership of R.R Enterprise, ZEST is dedicated to upholding its reputation for quality and flavor. As we continue to grow, we invite you to join us on this exciting journey, where every spice tells a story and every dish becomes a celebration of taste!
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className={`${style.about_sec_perent} row pb-lg-5 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4 `}>
                        <div className={`${style.about_sec_child} col-lg-6 col-md-6 col-12 py-4 px-lg-4`} data-aos="fade-down">
                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                <strong className={`${style.about_regular_txt} fs-2`}>
                                    3. Why Choose ZestSpices?
                                </strong>
                                <p className={`${style.about_regular_txt} m-0 text-left`}>
                                    <ul className='py-1'>
                                        <li className='py-1'>
                                            Fresh & Quality Products: We carefully source fresh produce, premium meats, dairy, and pantry essentials from trusted suppliers.
                                        </li>
                                        <li className='py-1'>
                                            Convenience at Your Fingertips: Browse and shop from the comfort of your home, and enjoy quick, hassle-free delivery.
                                        </li>
                                        <li className='py-1'>
                                            Extensive Selection: From organic and local options to international brands, ZestSpices offers a diverse range of grocery products.
                                        </li>
                                        <li className='py-1'>
                                            Affordable Prices: We are committed to offering you top-quality groceries at prices that fit your budget.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 py-4 text-center' data-aos="fade-up">
                            <div className='d-flex justify-content-center align-items-center text-center'>
                                <img alt='test' className={`${style.side_img}`} src={aboutZestImg} width={'350px'} />
                            </div>
                        </div>
                    </div>

                    <div className='row pb-lg-5 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4'>
                        <div className='col-lg-6 col-md-6 col-12 py-4 text-center' data-aos="fade-down">
                            <div className='d-flex justify-content-center align-items-center text-center'>
                                <img alt='test' className={`${style.side_img}`} src={aboutZestWorkImg} width={'350px'} />
                            </div>
                        </div>
                        <div className={`${style.about_sec_child} col-lg-6 col-md-6 col-12 py-4 px-lg-4`} data-aos="fade-up">
                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                <strong className={`${style.about_regular_txt} fs-2`}>
                                    4. How ZestSpices Works :
                                </strong>
                                <p className={`${style.about_regular_txt} m-0 text-left`}>
                                    <ul className='py-1'>
                                        <li className='py-1'>
                                        Sourcing : We carefully select the finest raw spices from trusted farms, ensuring top-quality ingredients
                                        </li>
                                        <li className='py-1'>
                                        Processing : Our spices are processed using state-of-the-art technology, maintaining purity and preserving flavor.
                                        </li>
                                        <li className='py-1'>
                                        Quality Control : Each batch undergoes rigorous quality checks to meet international standards of freshness and consistency.
                                        </li>
                                        <li className='py-1'>
                                        Packaging : Our products are hygienically packed to lock in freshness, ensuring they reach you in perfect condition.
                                        </li>
                                        <li className='py-1'>
                                        Distribution : With a robust supply chain, we efficiently export our products to six countries around the world
                                        </li>
                                        <li className='py-1'>
                                        Customer Delivery : We ensure timely and reliable delivery, bringing ZEST spices from our facilities straight to your kitchen
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className={`${style.about_sec_perent} row pb-lg-5 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4 `}>
                        <div className={`${style.about_sec_child} col-lg-6 col-md-6 col-12 py-4 px-lg-4`} data-aos="fade-down">
                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                <strong className={`${style.about_regular_txt} fs-2`}>
                                    5. Our Commitment to Safety :
                                </strong>
                                <p className={`${style.about_regular_txt} m-0 text-left`}>
                                At ZEST, we prioritize safety by following strict quality standards and 
                                hygiene protocols, sourcing certified and traceable raw materials, and 
                                ensuring safe packaging to prevent contamination. We comply with 
                                international food safety regulations and continually enhance our 
                                processes to meet industry standards and exceed customer 
                                expectations.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 py-4 text-center' data-aos="fade-up">
                            <div className='d-flex justify-content-center align-items-center text-center'>
                                <img alt='test' className={`${style.side_img}`} src={aboutSafteySecImg} width={'350px'} />
                            </div>
                        </div>
                    </div>

                    <div className='row pb-lg-5 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4'>
                        <div className='col-12 py-4 about-sec-child px-lg-4' data-aos="fade-up">
                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                <strong className={`${style.about_regular_txt} fs-2`}>
                                    6. Contact Us :
                                </strong>
                                <p>
                                    Have questions or need assistance? We’re here to help! Reach out to our friendly customer service team via email at <a href="mailto:zestfood@gmail.com">zestfood@gmail.com</a> or call us at +91 9727547477. We’re excited to serve you!
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className='pb-5'>
                        <div className='row pb-lg-3 pb-4 d-flex justify-content-center align-items-center px-lg-0 px-4' data-aos="fade-up">
                            <strong className={`${style.about_regular_txt} fs-2`}>
                                7. Join the ZestSpices Community:
                            </strong>
                        </div>
                        <div className={`${style.community_sec} row d-flex justify-content-center align-items-center px-lg-0 px-4 `} data-aos="flip-up">
                            <div className={`${style.cummunity_sec_child} col-12 py-4  px-lg-4`}>
                                <p className='fs-5 text-white'>
                                    Become a part of the ZestSpices community! Follow us on social media for the latest news, tips, and special offers. Sign up for our newsletter to stay informed about new products, promotions, and exciting updates.
                                </p>
                                <div className='d-flex justify-content-center align-items-start gap-5' data-aos="fade-up">
                                    <i className="fab fa-facebook-square icon display-1" style={{ cursor: 'pointer' }}></i>
                                    <i className="fab fa-twitter-square icon display-1" style={{ cursor: 'pointer' }}></i>
                                    <i className="fab fa-instagram icon display-1" style={{ cursor: 'pointer' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>
    </>
  );
};

export default About;
