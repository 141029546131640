import React, { useEffect } from "react";
import style from "./footer.module.css";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  const onRedirectPage = (value) => {
    if (value) {
      if (value === "Facebook") {
        window.open("https://www.facebook.com", "_blank");
      } else if (value === "Twitter") {
        window.open("https://www.twitter.com", "_blank");
      } else if (value === "Linkedin") {
        window.open("https://www.linkdin.com", "_blank");
      } else {
        window.open("https://www.youtube.com", "_blank");
      }
    }
  };
  return (
    <>
      <div className={`${style.footer_sec} text-white`}>
        <div className="container">
          <div className="row  py-4">
            <div
              className={`${style.mobile_view_footer} col-lg-4 col-md-4 col-12 pb-3`}
            >
              <i
                className={`${style.contact_txt} bi bi-geo-alt`}
                style={{ color: "orange", fontSize: "25px" }}
              >
                {" "}
                Address
              </i>
              <p className={`${style.contact_regular_txt} pt-2 ps-1`}>
                <strong>Zest</strong>
                <br />
                <span>
                  B-77,Sidhharth Nagar Society,
                  <br />
                  Simada Gam,
                  <br />
                  Surat, 395006
                </span>
              </p>
            </div>
            <div
              className={`${style.mobile_view_footer} col-lg-4 col-md-4 col-12 pb-3`}
            >
              <i
                className={`${style.contact_txt} bi bi-globe`}
                style={{ fontSize: "25px", color: "orange" }}
              >
                {" "}
                Follow Us
              </i>
              <p
                className={`${style.contact_regular_txt} ${style.social_link_sec} pt-2 ps-1 d-flex justfy-content-center flex-column align-items-start`}
              >
                <span className="py-2 d-flex jusitfy-content-center align-items-center gap-4">
                  <i
                    className="bi bi-facebook"
                    style={{ fontSize: "24px", color: "orange" }}
                    onClick={() => onRedirectPage("Facebook")}
                  ></i>
                  <i
                    className="bi bi-twitter"
                    style={{ fontSize: "24px", color: "orange" }}
                    onClick={() => onRedirectPage("Twitter")}
                  ></i>
                  <i
                    className="bi bi-linkedin"
                    style={{ fontSize: "24px", color: "orange" }}
                    onClick={() => onRedirectPage("Linkedin")}
                  ></i>
                </span>
                <span className="py-2">
                  <i
                    className="bi bi-youtube"
                    style={{ fontSize: "24px", color: "orange" }}
                    onClick={() => onRedirectPage("Youtube")}
                  ></i>
                </span>
              </p>
            </div>
            <div
              className={`${style.mobile_view_footer} col-lg-4 col-md-4 col-12`}
            >
              <i
                className={`${style.contact_txt} bi bi-info-circle`}
                style={{ fontSize: "24px", color: "orange" }}
              >
                {" "}
                About Zest
              </i>
              <p
                className={`${style.contact_regular_txt} pt-2 ps-1 d-flex justfy-content-center flex-column align-items-start`}
              >
                <span className={`${style.social_urls_pages} pb-2 fs-6`}>
                  <Link to="/">Home</Link>
                </span>
                <span className={`${style.social_urls_pages} pb-2 fs-6`}>
                  <Link to="/about">About</Link>
                </span>
                <span className={`${style.social_urls_pages} pb-2 fs-6`}>
                  <Link to="/products">Products</Link>
                </span>
                <span className={`${style.social_urls_pages} pb-2 fs-6`}>
                  <Link to="/contact">Contact us</Link>
                </span>
                <span className="pb-2 fs-6">
                  <span>
                    <input
                      type="text"
                      name="sendInvitationEmail"
                      placeholder="Enter email"
                      className={`${style.footer_inpt}`}
                    />
                    <button className={`${style.btn_footer_email}`}>
                      Send Link
                    </button>
                  </span>
                </span>
              </p>
            </div>
          </div>
          <hr className="h-0 m-0" />
          <div className="row">
            <span className="m-0 py-3 text-center">
              © Copyright <strong>Zest Food.</strong> All Rights Reserved
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
