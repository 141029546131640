import React from 'react';
import styles from "./FloatingButton.module.css"
import './FloatingButton.module.css'; // Import the styles

const FloatingButton = () => {
    const handleDownload = () => {
        // PDF is located in the public folder, so you can provide a direct link
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/sample.pdf`; // Ensure this matches your file path
        link.download = 'sample.pdf'; // Name of the file after downloading
        link.click();
      };
      
  return (
    <div className={`${styles.floating_button}`}>
      <button onClick={handleDownload}>Download Brochure</button>
    </div>
  );
};

export default FloatingButton;
