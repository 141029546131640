import React from "react";
import style from "./banner.module.css";
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y,Autoplay} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Banner1 from "../../../assets/Images/Home/HomeBanner/1.jpg";
import Banner2 from "../../../assets/Images/Home/HomeBanner/2.jpg";
import Banner3 from "../../../assets/Images/Home/HomeBanner/3.jpg";
import Banner4 from "../../../assets/Images/Home/HomeBanner/4.jpg";
import Banner5 from "../../../assets/Images/Home/HomeBanner/5.jpg";
import Banner6 from "../../../assets/Images/Home/HomeBanner/6.jpg";
import Banner7 from "../../../assets/Images/Home/HomeBanner/7.jpg";
import Banner8 from "../../../assets/Images/Home/HomeBanner/8.jpg";


const Banner = () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        navigation
        pagination={{ clickable: true }}
        loop={true}
      >
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner1})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Pure Flavor Perfection</strong>
              </div>
              <div className={`${style.subtitle_inner}`}>
                <h2>Transform your cooking with ZEST</h2>
              </div>
              <div className="">
                <p className={`${style.banner_desc}`}>
                Unlock a world of flavor with our premium spices, sourced globally to elevate your everyday cooking.
                </p>
              </div>
              <div className="">
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner2})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Authentic Taste Guaranteed</strong>
              </div>
              <div>
                <h2>Add a dash of zest to your culinary creations</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Transform your kitchen into a culinary destination with our expertly crafted blends of global spices and seasonings.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner3})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Freshness Meets Flavor</strong>
              </div>
              <div>
                <h2>Spices bring taste to your kitchen</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Experience the essence of tradition and innovation with ZEST, where every spice tells a unique flavor story.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner4})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Spice Your Life</strong>
              </div>
              <div>
                <h2>Where spices meet perfection in your kitchen.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Explore the finest spices from around the world, bringing bold, authentic flavors to your favorite dishes.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner5})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Quality Crafted Spices</strong>
              </div>
              <div>
                <h2>Transform ordinary meals with extraordinary spices.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                From farm to table, our spices deliver unmatched freshness, quality, and taste to every recipe you create.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner6})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Flavors Unleashed Daily</strong>
              </div>
              <div>
                <h2>Adding a world of flavor to your table.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Elevate your culinary creations with our diverse range of carefully sourced spices and artisanal seasonings.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner7})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Premium Spice Experience</strong>
              </div>
              <div>
                <h2>Unlock the secret to perfect seasoning.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Discover the true potential of your kitchen with our handpicked spices, designed to inspire creativity and flavor.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner8})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Zestful Cooking Always</strong>
              </div>
              <div>
                <h2>Where fresh spices meet unforgettable taste.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Our expertly blended spices and seasonings add vibrant flavors to meals, making every bite a culinary experience.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner5})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Bold Flavor Adventures</strong>
              </div>
              <div>
                <h2>Flavors that spark culinary creativity.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Spice up your dishes with global flavors, expertly curated to turn everyday meals into gourmet delights.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div
            className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
            style={{ backgroundImage: `url(${Banner1})` }}
          >
            <div className={`${style.banner_content} w-100`}>
              <div className={style.subtitle}>
                <strong>Taste Beyond Expectations</strong>
              </div>
              <div>
                <h2>Spices that turn meals into memories.</h2>
              </div>
              <div>
                <p className={`${style.banner_desc}`}>
                Bringing the rich aromas and vibrant flavors of global spices directly to your kitchen, one sprinkle at a time.
                </p>
              </div>
              <div>
                <Link to="/discover">
                  <button className={style.banner_btn}>DISCOVER NOW</button>
                </Link>
              </div>
            </div>
            {/* You might want to implement a carousel here */}
            <div className={`${style.hero_slides}`}></div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Banner;
