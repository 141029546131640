import React from "react";
import style from "./products.module.css";
import { Link } from "react-router-dom";
import all_product from "../../assets/Images/Products/all_product.jpg";
import social_media from "../../assets/Images/Products/social_media.avif";
import blended_banner from "../../assets/Images/Products/blended_banner.avif";
import pureSpice_banner from "../../assets/Images/Products/pureSpice_banner.avif";
import Wholespice_banner from "../../assets/Images/Products/Wholespice_banner.jpg";
import grid_first from "../../assets/Images/Products/grid_1.jpg";
import grid_second from "../../assets/Images/Products/grid_2.jpg";
import grid_three from "../../assets/Images/Products/grid_3.jpg";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useLocation, useNavigate } from "react-router-dom";
import Fancybox from "../../ComonComponents/FancyBox/fancybox";
import BlendedSpicesPro from "../../assets/Images/Products/img/BlendedSpices.jpeg"
import PureSpices from "../../assets/Images/Products/img/PURE SPICES.jpeg"
import WholeSpices from "../../assets/Images/Products/img/WHOLE SPICES.jpeg"
import Primix from "../../assets/Images/Products/img/PRIMIX SPICES.jpg"
import Herbsmix from "../../assets/Images/Products/img/HERBSMIX SPICES.jpg"
import Seasonings from "../../assets/Images/Products/img/SEASONINGS SPICES.jpg"
import CinnamonStickes from "../../assets/Images/Products/img/CINNAMON STICKES.png"
import Primix1 from "../../assets/Images/Sambhar Premix/11.jpg";
import Primix2 from "../../assets/Images/Sambhar Premix/22.jpg";
import Primix3 from "../../assets/Images/Sambhar Premix/333.jpg";
import Primix4 from "../../assets/Images/Sambhar Premix/444.jpg";
import Primix5 from "../../assets/Images/Sambhar Premix/555.jpg";
import GarlicBread1 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/11.jpg";
import GarlicBread2 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/22.jpg";
import GarlicBread3 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/33.jpg";
import GarlicBread4 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/44.jpg";
import GarlicBread5 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/55.jpg";
import GarlicBread6 from "../../assets/Images/Garlic Bread Sesoning/Garlic Bread Sesoning/66.jpg";
import Pizza1 from "../../assets/Images/Pizza Seasoning Post/111.jpg";
import Pizza2 from "../../assets/Images/Pizza Seasoning Post/222.jpg";
import Pizza3 from "../../assets/Images/Pizza Seasoning Post/333.jpg";
import Pizza4 from "../../assets/Images/Pizza Seasoning Post/444.jpg";
import Pizza5 from "../../assets/Images/Pizza Seasoning Post/555.jpg";
import Pizza6 from "../../assets/Images/Pizza Seasoning Post/666.jpg";
import Cheese1 from "../../assets/Images/Cheese/E-Commerce Post_page-0001.jpg";
import Cheese2 from "../../assets/Images/Cheese/E-Commerce Post_page-0002.jpg";
import Cheese3 from "../../assets/Images/Cheese/E-Commerce Post_page-0003.jpg";
import Cheese4 from "../../assets/Images/Cheese/E-Commerce Post_page-0004.jpg";
import Cheese5 from "../../assets/Images/Cheese/E-Commerce Post_page-0005.jpg";
import Cheese6 from "../../assets/Images/Cheese/E-Commerce Post_page-0006.jpg";
import Tomatocheese1 from "../../assets/Images/Tomato cheese/111.jpg";
import Tomatocheese2 from "../../assets/Images/Tomato cheese/222.jpg";
import Tomatocheese3 from "../../assets/Images/Tomato cheese/333.jpg";
import Tomatocheese4 from "../../assets/Images/Tomato cheese/444.jpg";
import Tomatocheese5 from "../../assets/Images/Tomato cheese/555.jpg";
import Tomatocheese6 from "../../assets/Images/Tomato cheese/666.jpg";
import TengyTometo1 from "../../assets/Images/Tengy Tometo/111.jpg";
import TengyTometo2 from "../../assets/Images/Tengy Tometo/222.jpg";
import TengyTometo3 from "../../assets/Images/Tengy Tometo/333.jpg";
import TengyTometo4 from "../../assets/Images/Tengy Tometo/444.jpg";
import TengyTometo5 from "../../assets/Images/Tengy Tometo/555.jpg";
import TengyTometo6 from "../../assets/Images/Tengy Tometo/666.jpg";
import PeriPeri1 from "../../assets/Images/peri peri/111.jpg";
import PeriPeri2 from "../../assets/Images/peri peri/222.jpg";
import PeriPeri3 from "../../assets/Images/peri peri/333.jpg";
import PeriPeri4 from "../../assets/Images/peri peri/444.jpg";
import PeriPeri5 from "../../assets/Images/peri peri/555.jpg";
import PeriPeri6 from "../../assets/Images/peri peri/666.jpg";
import Pudina1 from "../../assets/Images/Pudina/111.png";
import Pudina2 from "../../assets/Images/Pudina/222.png";
import Pudina3 from "../../assets/Images/Pudina/333.png";
import LemonTest1 from "../../assets/Images/Lemon Test/111.png";
import LemonTest2 from "../../assets/Images/Lemon Test/222.png";
import LemonTest3 from "../../assets/Images/Lemon Test/333.png";
import Jainperi1 from "../../assets/Images/Jain Peri peri/111.png";
import Jainperi2 from "../../assets/Images/Jain Peri peri/222.png";
import Jainperi3 from "../../assets/Images/Jain Peri peri/333.png";
import BlendedSpices from "../../assets/Images/BlendedSpices/blendedmain.avif"
import DefaultPng from "../../assets/Images/Products/img/default-image.png"
import KashmiriChilliPowder from "../../assets/Images/Products/img/Kashmiri Chilli Powder.jpg"
import TurmericPowder from "../../assets/Images/Products/img/Turmeric Powder.jpg"
import CuminCorianderPowder from "../../assets/Images/Products/img/Cumin Coriander Powder.jpg";
import RedChilliPowder from "../../assets/Images/Products/img/Red Chilli Powder.jpg";



const Products = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const productDetails = queryParams.get("product-details");

  const handleClick = (category) => {
    navigate(`/products?category=${encodeURIComponent(category)}`);
  };

  const handleGallaryClick = (productDetails) => {
    navigate(`/product-detail?detail=${encodeURIComponent(productDetails)}`);
  };

  // console.log(ReactFancyBox,',<<<<<');

  return (
    <>
      {category == null && productDetails == null && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${all_product})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <p className={`${style.category}`}>PRODUCTS</p>
                  <h2>All PRODUCTS</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>All Products</h1>
              <p>
                Indulge in products that guarantee delicious taste in every
                bite. Discover a world of flavours with Zest wide range of
                items. From ready-to-use products like Mayonnaise, Pasta & Pizza
                sauces, Peanut Butters to ready-to-eat Cakes, Waffles &
                Brownies, we have everything you need to make all meals
                lip-smacking. Browse our range of products now and embark on a
                journey of taste and excellence.
              </p>
            </div>

            <div className={`${style.angry_grid} py-5`}>
              <div className={`${style.item_0}`}>
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("blendedspices")}
                >
                  <img
                    src={BlendedSpicesPro}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>BLENDED SPICES</p>
                    {/* <h3 className={`${style.product_title}`}>
                      blended spices 245g
                    </h3> */}
                    <p className={`${style.category}`}>5 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${style.item_1}`} id="item-1">
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("PureSpices")}
                >
                  <img
                    src={PureSpices}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>PURE SPICES</p>
                    {/* <h3 className={`${style.product_title}`}>
                      Pure Spices 245g
                    </h3> */}
                    <p className={`${style.category}`}>4 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${style.item_2}`} id="item-2">
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("wholespices")}
                >
                  <img
                    src={WholeSpices}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>WHOLE SPICES</p>
                    {/* <h3 className={`${style.product_title}`}>
                      Whole Spices 245g
                    </h3> */}
                    <p className={`${style.category}`}>7 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${style.item_3}`} id="item-3">
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("Premix")}
                >
                  <img
                    src={Primix}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>PREMIX</p>
                    {/* <h3 className={`${style.product_title}`}>Premix 245g</h3> */}
                    <p className={`${style.category}`}>1 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${style.item_4}`} id="item-4">
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("HerbsMix")}
                >
                  <img
                    src={Herbsmix}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>HERBS MIX</p>
                    {/* <h3 className={`${style.product_title}`}>HerbsMix 245g</h3> */}
                    <p className={`${style.category}`}>5 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={`${style.item_5}`}
                id="item-5"
                style={{ backgroundImage: `url(${grid_first})` }}
              ></div>
              <div className={`${style.item_6}`} id="item-6">
                {" "}
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("Seasonings")}
                >
                  <img
                    src={Seasonings}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>SEASONINGS</p>
                    {/* <h3 className={`${style.product_title}`}>
                      Seasonings 245g
                    </h3> */}
                    <p className={`${style.category}`}>7 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={`${style.item_7}`}
                id="item-7"
                style={{ backgroundImage: `url(${grid_second})` }}
              ></div>
              <div className={`${style.item_8}`} id="item-8">
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("dehydrated-vegetables")}
                >
                  <img
                    src={DefaultPng}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>DEHYDRATED VEGETABLES</p>
                    {/* <h3 className={`${style.product_title}`}>
                      Cinnamon Sticks 245g
                    </h3> */}
                    <p className={`${style.category}`}>20 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={`${style.item_9}`}
                id="item-9"
                style={{ backgroundImage: `url(${grid_three})` }}
              ></div>
            </div>

            <div className={`${style.productInner} py-4`}>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleClick("blendedspices")}
                  >
                    <img
                      src={BlendedSpicesPro}
                      alt="Mayonnaise Classic 245g"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>BLENDED SPICES</p>
                      {/* <h3 className={`${style.product_title}`}>
                        blended spices 245g
                      </h3> */}
                      <p className={`${style.category}`}>5 PRODUCTS</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleClick("PureSpices")}
                  >
                    <img
                      src={PureSpices}
                      alt="Mayonnaise Classic 245g"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PURE SPICES</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Pure Spices 245g
                      </h3> */}
                      <p className={`${style.category}`}>4 PRODUCTS</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleClick("wholespices")}
                  >
                    <img
                       src={WholeSpices}
                      alt="Mayonnaise Classic 245g"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>WHOLE SPICES</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Whole Spices 245g
                      </h3> */}
                      <p className={`${style.category}`}>7 PRODUCTS</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleClick("Premix")}
                  >
                    <img
                      src={Primix}
                      alt="Mayonnaise Classic 245g"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PREMIX</p>
                      {/* <h3 className={`${style.product_title}`}>Premix 245g</h3> */}
                      <p className={`${style.category}`}>1 PRODUCTS</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleClick("HerbsMix")}
                  >
                    <img
                      src={Herbsmix}
                      alt="Mayonnaise Classic 245g"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>HERBS MIX</p>
                      {/* <h3 className={`${style.product_title}`}>
                        HerbsMix 245g
                      </h3> */}
                      <p className={`${style.category}`}>5 PRODUCTS</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleClick("Seasonings")}
                  >
                    <img
                      src={Seasonings}
                      alt="Mayonnaise Classic 245g"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>SEASONINGS</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Seasonings 245g
                      </h3> */}
                      <p className={`${style.category}`}>7 PRODUCTS</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 py-3">
                  <img
                    className={`${style.grid_first}`}
                    src={grid_first}
                    alt="ll"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 py-3">
                  <img
                    className={`${style.grid_first}`}
                    src={grid_second}
                    alt="ll"
                  />
                </div>
              </div>

              <div className="row py-5">
                <div
                  className={`${style.card}`}
                  onClick={() => handleClick("dehydrated-vegetables")}
                >
                  <img
                    src={DefaultPng}
                    alt="Mayonnaise Classic 245g"
                    className={`${style.product_image}`}
                  />
                  <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>DEHYDRATED VEGETABLES</p>
                    {/* <h3 className={`${style.product_title}`}>
                      Cinnamon Sticks 245g
                    </h3> */}
                    <p className={`${style.category}`}>20 PRODUCTS</p>
                    <a href="#" className={`${style.see_product}`}>
                      SEE PRODUCT →
                    </a>
                  </div>
                </div>
                <div className="col-12 py-3 product_inner">
                  <Swiper
                    // install Swiper modules
                    modules={[A11y, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={3}
                    loop={true}
                    autoplay={{
                      delay: 3000, // Time between slides in milliseconds (3 seconds)
                      disableOnInteraction: false, // Continue autoplay even after user interaction
                    }}
                    // pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      375: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      425: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },

                      // 768: {
                      //   slidesPerView: 2,
                      //   spaceBetween: 40,
                      // },
                      // when window width is >= 1024px
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div
                        className="thumb-wrapper"
                        onClick={() => handleClick("Chili-Powder")}
                      >
                        <span className="wish-icon">
                          <i className="fa fa-heart-o"></i>
                        </span>
                        <div className="img-box">
                          <img
                            src={DefaultPng}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card_content">
                          <p className="category">CHILLI POWDER</p>
                          {/* <h3 className="product_title">Chili Powder 245g</h3> */}
                          <p className={`${style.category}`}>0 PRODUCTS</p>
                          <a href="#" className="see_product">
                            SEE PRODUCT →
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="thumb-wrapper"
                        onClick={() => handleClick("Cayenne-Pepper")}
                      >
                        <span className="wish-icon">
                          <i className="fa fa-heart-o"></i>
                        </span>
                        <div className="img-box">
                          <img
                            src={DefaultPng}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card_content">
                          <p className="category">CAYENNE PEPPER</p>
                          {/* <h3 className="product_title">Cayenne Pepper 245g</h3> */}
                          <p className={`${style.category}`}>0 PRODUCTS</p>
                          <a href="#" className="see_product">
                            SEE PRODUCT →
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="thumb-wrapper"
                        onClick={() => handleClick("Ginger")}
                      >
                        <span className="wish-icon">
                          <i className="fa fa-heart-o"></i>
                        </span>
                        <div className="img-box">
                          <img
                            src={DefaultPng}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card_content">
                          <p className="category">GINGER</p>
                          {/* <h3 className="product_title">Ginger Spices 245g</h3> */}
                          <p className={`${style.category}`}>0 PRODUCTS</p>
                          <a href="#" className="see_product">
                            SEE PRODUCT →
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="thumb-wrapper"
                        onClick={() => handleClick("Fenugreek")}
                      >
                        <span className="wish-icon">
                          <i className="fa fa-heart-o"></i>
                        </span>
                        <div className="img-box">
                          <img
                            src={DefaultPng}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="card_content">
                          <p className="category">FENUGREEK</p>
                          {/* <h3 className="product_title">Fenugreek 245g</h3> */}
                          <p className={`${style.category}`}>0 PRODUCTS</p>
                          <a href="#" className="see_product">
                            SEE PRODUCT →
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {category == "blendedspices" && (
        <>
            <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>BLENDED SPICES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>Blended</h1>
              <p>
              Whole spices are natural, unprocessed spices in their original form, typically as seeds, pods, bark, or stems. Unlike ground spices, which are crushed into powder, whole spices maintain their integrity and are often used in cooking to release flavors slowly over time. They are known for their ability to add depth, aroma, and complexity to dishes, making them a vital component in many culinary traditions.
              </p>
            </div>

            <div className="py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("turmeric-powder")}
                  >
                    <img
                      src={TurmericPowder}
                      alt="TurmericPowder"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>TURMERIC POWDER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("garam-masala")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>GARAM MASALA</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("kutchi-dabeli")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>KUTCHI DABELI MASALA</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("panipuri")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PANI PURI</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("sambhar")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>SAMBHAR MASALA</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("chilli-Powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CHILLI POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("coriander-cumin-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CORIANDER-CUMIN POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("coriander-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CORIANDER POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("kashmiri-red-chilli-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>KASHMIRI RED CHILLI POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </>
      )}
      {category == "PureSpices" && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${pureSpice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>PURE SPICES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>Pure Spices</h1>
              <p>
              Pure spices are natural flavoring agents derived from various plants, seeds, roots, bark, and fruits. They are essential ingredients in cuisines around the world, adding depth, heat, and complexity to dishes. Spices such as turmeric, cumin, coriander, black pepper, and cinnamon not only enhance the flavor of food but also bring numerous health benefits. Turmeric, for example, is known for its anti-inflammatory properties, while cumin aids digestion. Black pepper, often called the "king of spices," boosts metabolism and enhances nutrient absorption
              </p>
            </div>
            <div className="py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("turmeric-powder")}
                  >
                    <img
                      src={TurmericPowder}
                      alt="TurmericPowder"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>TURMERIC POWDER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("chilli-Powder")}
                  >
                    <img
                      src={KashmiriChilliPowder}
                      alt="KashmiriChilliPowder"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CHILLI POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("coriander-cumin-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CORIANDER-CUMIN POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("coriander-powder")}
                  >
                    <img
                      src={CuminCorianderPowder}
                      alt="CuminCorianderPowder"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CORIANDER POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("kashmiri-red-chilli-powder")}
                  >
                    <img
                      src={RedChilliPowder}
                      alt="RedChilliPowder"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>KASHMIRI RED CHILLI POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {category == "wholespices" && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>WHOLE SPICES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>Whole</h1>
              <p>
              Whole spices are natural, unprocessed spices in their original form, typically as seeds, pods, bark, or stems. Unlike ground spices, which are crushed into powder, whole spices maintain their integrity and are often used in cooking to release flavors slowly over time. They are known for their ability to add depth, aroma, and complexity to dishes, making them a vital component in many culinary traditions.
              </p>
            </div>

            <div className="py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Star-Anise")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>STAR ANISE</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Finger-Cassia")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>FINGER CASSIA</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("split-cassia")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>SPLIT CASSIA</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("clove")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CLOVE (LALPERI)</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("cardamom")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CARDAMOM</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("black-pepper")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>BLACK PEPPER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("ceylon-cinnamon-stick")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CEYLON CINNAMON STICK</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {category == "Premix" && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>PRIMIX SPICES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>Premix</h1>
              <p>
                Premix refers to a ready-made blend of various ingredients,
                often including spices, herbs, and other seasonings, designed to
                simplify cooking by providing a balanced mix of flavors.
                Premixes are commonly used to create specific dishes or flavors
                without the need for measuring individual ingredients. They save
                time and ensure consistency in taste.
              </p>
            </div>

            <div className="py-4">
              <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("sambhar")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>SAMBHAR MASALA</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {category == "HerbsMix" && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>HERBSMIX SPICES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>HerbsMix Spices</h1>
              <p>
                Herb mixes, also known as herb blends, are combinations of
                various dried or fresh herbs that are used to season and enhance
                the flavor of food. These blends often reflect specific cuisines
                or cooking styles and can be customized to suit different flavor
                profiles.
              </p>
            </div>

            <div className="py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Garlic-Bread")}
                  >
                    <img
                      src={GarlicBread1}
                      alt="GarlicBread1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>
                        GARLIC BREAD SEASONING
                      </p>
                      {/* <h3 className={`${style.product_title}`}>
                        Garlic bread 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Pizza")}
                  >
                    <img
                      src={Pizza1}
                      alt="Pizza1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PIZZA SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>Pizza 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("dried-Oregano")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>DRIED OREGANO</p>
                      {/* <h3 className={`${style.product_title}`}>Oregano 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("dried-chilli-flackes")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>DRIED CHILLI FLACKES</p>
                      {/* <h3 className={`${style.product_title}`}>Oregano 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("dried-basil")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>DRIED BASIL</p>
                      {/* <h3 className={`${style.product_title}`}>Oregano 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {category == "Seasonings" && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>SEASONINGS SPICES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>Seasonings Spices</h1>
              <p>
                Seasoning spices refer to a combination of herbs, spices, and
                other ingredients used to enhance the flavor of food. These
                blends or individual spices are crucial in cooking, bringing
                depth, warmth, and complexity to dishes
              </p>
            </div>

            <div className="py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Cheddar Cheese")}
                  >
                    <img
                      src={Cheese1}
                      alt="Cheese1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                    <p className={`${style.category}`}>CHEDDAR CHEESE SEASONING</p>
                    
                      {/* <span className={`${style.category}`}>Application : Prime Seasoning/Flavour</span>
                      <span className={`${style.category}`}>Application : Prime Seasoning/Flavour</span>
                      <span className={`${style.category}`}>Application : Prime Seasoning/Flavour</span>
                      <span className={`${style.category}`}>Application : Prime Seasoning/Flavour</span>
                      <span className={`${style.category}`}>Application : Prime Seasoning/Flavour</span> */}

                      {/* <h3 className={`${style.product_title}`}>Cheese 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Tomato-Cheese")}
                  >
                    <img
                      src={Tomatocheese1}
                      alt="Tomatocheese1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>TOMATO CHEESE SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Tomato Cheese 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Tangy-Tomatino")}
                  >
                    <img
                      src={TengyTometo1}
                      alt="TengyTometo1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>TANGY TOMATINO SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Tangy Tomatino 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Peri-Peri")}
                  >
                    <img
                      src={PeriPeri1}
                      alt="PeriPeri1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PERI PERI MASALA SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Peri Peri 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Pudina")}
                  >
                    <img
                      src={Pudina1}
                      alt="PeriPeri1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PUDINA SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Peri Peri 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Lemon-Test")}
                  >
                    <img
                      src={LemonTest1}
                      alt="PeriPeri1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>LEMON TEST SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Peri Peri 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5  d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("Jain-Peri-Peri-Seasoning")}
                  >
                    <img
                      src={Jainperi1}
                      alt="Jainperi1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>JAIN PERI PERI MASALA SEASONING</p>
                      {/* <h3 className={`${style.product_title}`}>
                        Peri Peri 245g
                      </h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {category == "Cinnamon" && (
        <>
          <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>CINNAMON STICKES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>No Record</h1>
              <p>
                It seems like you're asking about a product availability issue.
                Could you clarify your question or provide more context? Are you
                looking for information about why a product is unavailable,
                trying to troubleshoot an issue with a website, or something
                else? Let me know how I can assist you!
              </p>
            </div>
          </section>
        </>
      )}
     
     {category == "dehydrated-vegetables" && (
        <>
            <section>
            <div
              className={`${style.hero_section} d-flex justify-content-center align-items-center container-fluid`}
              style={{ backgroundImage: `url(${Wholespice_banner})` }}
            >
              <div className={`${style.banner_content} w-100`}>
                <div className="py-3 d-flex justify-content-center text-center flex-column">
                  <h2>DEHYDRATED VEGETABLES</h2>
                </div>
              </div>
              <div className={`${style.hero_slides}`}></div>
            </div>
          </section>
          <section className={`${style.product_section} container p-3`}>
            <div className={`${style.all_products} py-5`}>
              <h1>DEHYDRATED VEGETABLES</h1>
              <p>
              Dehydrated vegetables are a versatile and potent spice ingredient that adds both flavor and nutrition to a variety of dishes. By removing the water content, the vegetables retain their natural flavors, while becoming more concentrated, making them ideal for use in seasoning blends, soups, stews, and other culinary applications.
              </p>
            </div>

            <div className="py-4">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("pink-onion-flackes")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PINK ONION FLACKES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("pink-onion-chopped")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PINK ONION CHOPPED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("pink-onion-minced")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PINK ONION MINCED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("pink-onion-granules")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PINK ONION GRANULES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("pink-onion-powder")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>PINK ONION POWDER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>


                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("red-onion-flackes")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>RED ONION FLACKES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("red-onion-chopped")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>RED ONION CHOPPED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("red-onion-minced")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>RED ONION MINCED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("red-onion-granules")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>RED ONION GRANULES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("red-onion-powder")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>RED ONION POWDER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>


                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("white-onion-flackes")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>WHITE ONION FLACKES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("white-onion-chopped")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>WHITE ONION CHOPPED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("white-onion-minced")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>WHITE ONION MINCED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("white-onion-granules")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>WHITE ONION GRANULES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("white-onion-powder")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>WHITE ONION POWDER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>


                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("garlic-flackes")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>GARLIC FLACKES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("garlic-chopped")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>GARLIC CHOPPED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("garlic-minced")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>GARLIC MINCED</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("garlic-granules")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>GARLIC GRANULES</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("garlic-powder")}
                  >
                    <img
                      src={DefaultPng}
                      alt="DefaultPng"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>GARLIC POWDER</p>
                      {/* <h3 className={`${style.product_title}`}>Sambhar 245g</h3> */}
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>

                
                {/* <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("chilli-Powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CHILLI POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("coriander-cumin-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CORIANDER-CUMIN POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("coriander-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>CORIANDER POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-3 py-5 d-flex justify-content-center">
                  {" "}
                  <div
                    className={`${style.card}`}
                    onClick={() => handleGallaryClick("kashmiri-red-chilli-powder")}
                  >
                    <img
                      src={Primix1}
                      alt="Primix1"
                      className={`${style.product_image}`}
                    />
                    <div className={`${style.card_content}`}>
                      <p className={`${style.category}`}>KASHMIRI RED CHILLI POWDER</p>
                      <a href="#" className={`${style.see_product}`}>
                        SEE PRODUCT →
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </>
      )}

      {/* {gallary == "Premix-Gallary" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Primix1}>
                    <img alt="" src={Primix1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Primix2}>
                    <img alt="" src={Primix2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Primix3}>
                    <img alt="" src={Primix3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Primix4}>
                    <img alt="" src={Primix4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Primix5}>
                    <img alt="" src={Primix5} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )} */}

      {/* {gallary == "Garlic-Bread" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
               ="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread1}>
                    <img alt="" src={GarlicBread1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread2}>
                    <img alt="" src={GarlicBread2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread3}>
                    <img alt="" src={GarlicBread3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread4}>
                    <img alt="" src={GarlicBread4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread5}>
                    <img alt="" src={GarlicBread5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread6}>
                    <img alt="" src={GarlicBread6} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread7}>
                    <img alt="" src={GarlicBread7} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread8}>
                    <img alt="" src={GarlicBread8} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread9}>
                    <img alt="" src={GarlicBread9} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread10}>
                    <img alt="" src={GarlicBread10} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread11}>
                    <img alt="" src={GarlicBread11} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread12}>
                    <img alt="" src={GarlicBread12} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread13}>
                    <img alt="" src={GarlicBread13} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread14}>
                    <img alt="" src={GarlicBread14} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread15}>
                    <img alt="" src={GarlicBread15} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread16}>
                    <img alt="" src={GarlicBread16} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread17}>
                    <img alt="" src={GarlicBread17} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={GarlicBread18}>
                    <img alt="" src={GarlicBread18} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )} */}

      {/* {gallary == "Pizza" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza1}>
                    <img alt="" src={Pizza1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza2}>
                    <img alt="" src={Pizza2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza3}>
                    <img alt="" src={Pizza3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza4}>
                    <img alt="" src={Pizza4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza5}>
                    <img alt="" src={Pizza5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza6}>
                    <img alt="" src={Pizza6} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )} */}

      {/* {gallary == "Oregano" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza1}>
                    <img alt="" src={Pizza1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza2}>
                    <img alt="" src={Pizza2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza3}>
                    <img alt="" src={Pizza3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza4}>
                    <img alt="" src={Pizza4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza5}>
                    <img alt="" src={Pizza5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Pizza6}>
                    <img alt="" src={Pizza6} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )}
      {gallary == "Cheese" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Cheese1}>
                    <img alt="" src={Cheese1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Cheese2}>
                    <img alt="" src={Cheese2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Cheese3}>
                    <img alt="" src={Cheese3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Cheese4}>
                    <img alt="" src={Cheese4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Cheese5}>
                    <img alt="" src={Cheese5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Cheese6}>
                    <img alt="" src={Cheese6} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )} */}

      {/* {gallary == "Tomato-Cheese" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Tomatocheese1}>
                    <img alt="" src={Tomatocheese1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Tomatocheese2}>
                    <img alt="" src={Tomatocheese2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Tomatocheese3}>
                    <img alt="" src={Tomatocheese3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Tomatocheese4}>
                    <img alt="" src={Tomatocheese4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Tomatocheese5}>
                    <img alt="" src={Tomatocheese5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={Tomatocheese6}>
                    <img alt="" src={Tomatocheese6} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )}

      {gallary == "Tangy-Tomatino" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometo1}>
                    <img alt="" src={TengyTometo1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometo2}>
                    <img alt="" src={TengyTometo2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometo3}>
                    <img alt="" src={TengyTometo3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometo4}>
                    <img alt="" src={TengyTometo4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometo5}>
                    <img alt="" src={TengyTometo5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometo6}>
                    <img alt="" src={TengyTometo6} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={TengyTometoPng}>
                    <img alt="" src={TengyTometoPng} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )} */}

      {/* {gallary == "Peri-Peri" && (
        <>
          <div className="d-flex justify-content-center align-items-center container-fluid">
            <Fancybox>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeri1}>
                    <img alt="" src={PeriPeri1} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeri2}>
                    <img alt="" src={PeriPeri2} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeri3}>
                    <img alt="" src={PeriPeri3} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeri4}>
                    <img alt="" src={PeriPeri4} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeri5}>
                    <img alt="" src={PeriPeri5} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeri6}>
                    <img alt="" src={PeriPeri6} width={400} height={400} />
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 py-5 d-flex justify-content-center align-items-center">
                  {" "}
                  <a data-fancybox="gallery" href={PeriPeriPng}>
                    <img alt="" src={PeriPeriPng} width={400} height={400} />
                  </a>
                </div>
              </div>
            </Fancybox>
          </div>
        </>
      )} */}

      {/* Garlic-Bread */}
    </>
  );
};

export default Products;
